import { Stack } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import EditIcon from '@mui/icons-material/Edit'
import CircleIcon from '@mui/icons-material/Circle'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton, Checkbox, ButtonGroup, Button } from '@mui/material'
import { toast } from 'react-toastify'

import EditCell from './EditCell'
import PopoverButton from './PopoverButton'

import useIsMounted from 'hooks/useIsMounted'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import DatetimeValue from 'components/TableUI/DatetimeValue'


function UrlView({ url }) {
    function ViewButton({ onClick }) {
        return <Button onClick={onClick}>
            <VisibilityIcon />
        </Button>
    }

    return <ButtonGroup variant="outlined" size="small">
        <Button onClick={() => window.open(url, "_blank")}>
            <OpenInNewIcon />
        </Button>
        <Button onClick={() => navigator.clipboard.writeText(url)}>
            <ContentCopyIcon />
        </Button>
        <PopoverButton ButtonComponent={ViewButton}>
            {url}
        </PopoverButton>
    </ButtonGroup>
}


function DataCell({ fieldMeta, value, editCallback = null }) {
    const [editing, setEditing] = useState(false)
    const [updatedValue, setUpdatedValue] = useState(value)

    const isMounted = useIsMounted()
    const { t } = useTranslation()

    const editButton = <IconButton style={{ opacity: 0.8, color: "darkslateblue" }} onClick={() => setEditing(true)}><EditIcon /></IconButton>
    const editButtonIf = editCallback === null ? "" : editButton

    const valueToShow = (() => {
        if (updatedValue === null) return <CircleIcon style={{ opacity: 0.1 }} />
        switch (fieldMeta.type) {
            case "choice": return fieldMeta.choices[updatedValue]
            case "boolean": return <Checkbox checked={updatedValue} style={{ color: "darkslateblue" }} disabled />
            case "url": return <UrlView url={updatedValue} />
            case "datetime": return <DatetimeValue date={updatedValue} />
            case "date": return <DatetimeValue date={updatedValue} hour={false} />
            default: return updatedValue
        }
    })()

    const readDisplay = <Stack direction="row" alignItems="center">{editButtonIf}{valueToShow}</Stack>

    function editCallback_(newValue) {
        if (arguments.length === 0) {
            setEditing(false)
            return
        }
        const promise = editCallback(newValue)
            .then(() => {
                if (isMounted()) {
                    setEditing(false)
                    setUpdatedValue(newValue)
                    toast.success(`${t(fieldMeta.label)} ${t("updated succesfully")}`)
                }
            })
        return promise
    }

    return <TableCell>
        {editing ?
            <EditCell editCallback={editCallback_} fieldMeta={fieldMeta} currentValue={updatedValue} />
            : readDisplay
        }
    </TableCell>
}


export function MakeRowDataCellsComponent(metadata, editableFields = new Set(), editCallback = (id, data) => Promise.resolve(), hiddenFields) {
    const fields = Object.keys(metadata.field_info).filter((field) => !hiddenFields.has(field))

    function fieldMeta(field) {
        return { field: field, ...metadata.field_info[field] }
    }

    function makeCellEditCallback(id, field) {
        return function CellEditCallback(value) {
            return editCallback(id, { [field]: value })
        }
    }

    function makeCellEditCallbackArg(id, field) {
        return editableFields.has(field) ? makeCellEditCallback(id, field) : null
    }

    // TODO: Return primary key from server and use dynamically instead of always id
    return function RowDataCells({ entry }) {
        return fields.map((field, idx) => (
            <DataCell
                key={idx}
                fieldMeta={fieldMeta(field)}
                value={entry[field]}
                editCallback={makeCellEditCallbackArg(entry["id"], field)}
            />
        ))
    }
}


export default function DataRow({ data, actions, extraColFuncs, metadata, editableFields, editCallback, hiddenFields }) {
    const DataCells = MakeRowDataCellsComponent(metadata, editableFields, editCallback, hiddenFields)

    return <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
        <DataCells entry={data} />
        {extraColFuncs.map((func, idx) => <TableCell key={idx}>{func(data)}</TableCell>)}
        {/*<TableCell align="center">
            <TableActions actions={[
                {
                    id: `edit-${row.id}`,
                    handleClick: () => { handleNavigate(`${row.id}/edit`) },
                    icon: hasPermissions(ADDRESSES__UPDATE) ? <EditIcon /> : <PreviewIcon />,
                    title: hasPermissions(ADDRESSES__UPDATE) ? t('Edit') : t('View')
                },
                {
                    id: `remove-${row.id}`,
                    handleClick: () => { return handleRemove(row.id) },
                    icon: <DeleteIcon />,
                    title: t('Remove'),
                    show: hasPermissions(ADDRESSES__DELETE)
                }
            ]} />
        </TableCell>*/}
    </TableRow>
}