import React from 'react'
import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Grid, Box, Stack, LinearProgress, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SelectWrapper from '../../../FormUI/SelectWrapper/SelectWrapper'
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper'
import SubmitWrapper from '../../../FormUI/SubmitWrapper/SubmitWrapper'
import GenericWrapper from "../../../FormUI/GenericWrapper/GenericWrapper";
import EditField from "components/Common/EditFields"
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SwitchWrapper from '../../../FormUI/SwitchWrapper/SwitchWrapper'
import { bank_accounts } from './../../../../enums/Merchants'
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useIsMounted from "../../../../hooks/useIsMounted";
import { objectFilter } from 'utils/jsUtils'
import { standardAccountTypes } from "./standardAccountTypes";


function groupByCountry(banks) {
    const result = {}
    for (const bank of banks) {
        if (!result[bank.country]) {
            result[bank.country] = []
        }
        result[bank.country].push(bank)
    }
    return result
}


export default function BankAccountsForm({
    isNew,
    initialFormState,
    FORM_VALIDATION,
    handleSubmit,
    merchant_id,
    hasPermissions
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const api = useAxiosPrivate();

    const isMounted = useIsMounted();

    // Used to save country name
    const [countriesBankList, setCountriesBankList] = useState(null);

    const [countryBankList, setCountryBankList] = useState(null);

    const [countryList, setCountryList] = useState(null);

    const [filteredCountryList, setFilteredCountryList] = useState(null);

    const [selectedCountry, setSelectedCountry] = useState(initialFormState?.bank?.country ?? initialFormState?.bank_country_old);

    useEffect(() => {
        const formatter = (objects) => Object.fromEntries(objects.map((object) => [object.id, object.name]))
        api.get('/internal/countries/')
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    const country_list = data.sort((a, b) => (a.name_es > b.name_es) ? 1 : ((b.name_es > a.name_es) ? -1 : 0))

                    setCountryList(formatter(
                        country_list.map((c) => ({
                            id: c.code,
                            name: c.name_es
                        })))
                    )
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
    }, [t, api, isMounted, setCountryList])


    // Get all banks
    useEffect(() => {
        api.get("/internal/banks/").then((response) => {
            if (isMounted()) {
                // response.data.data has the following format:
                // [
                //     {
                //         "code": "001",
                //         "name": "BANCO DE CHILE",
                //         "country": "CL"
                //     },
                //     ...
                // ]

                // index by country
                setCountriesBankList(groupByCountry(response.data.data))
            }
        })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
    }, [t, api, isMounted])

    // Filter countryBankList by country
    // This is used to show the banks of the selected country
    useEffect(() => {
        if (!(selectedCountry && countriesBankList)) {
            return
        }
        const filteredList = countriesBankList[selectedCountry] ?? []
        if (filteredList.length > 0) {
            setCountryBankList(Object.fromEntries(filteredList.map((object) => [object.id, object.name])))
        }
        else {
            setCountryBankList({})
        }

    }, [selectedCountry, countriesBankList])


    // Set filteredCountryList to countryList filtered by countries with banks
    useEffect(() => {
        if (!(countryList && countriesBankList)) {
            return
        }
        const filteredList = objectFilter(({ key }) => key in countriesBankList, countryList)
        setFilteredCountryList(filteredList)
    }, [countryList, countriesBankList, setFilteredCountryList])


    // Cuenta Rut account number replication code
    const [accountNumber, setAccountNumber] = useState(initialFormState.account_number)
    const [isCuentaRut, setIsCuentaRut] = useState(false)
    const [accountOwnerPersonalId, setAccountOwnerPersonalId] = useState(initialFormState.account_owner_personal_id)

    const replicateCuentaRut = (personalId) => {
        setAccountNumber(personalId.split("-")[0])
    }

    const handleAccountTypeChange = (event) => {
        const isCuentaRut_ = event.target.value === "CUENTA RUT"
        setIsCuentaRut(isCuentaRut_)
        if (isCuentaRut_) {
            replicateCuentaRut(accountOwnerPersonalId)
        }
    }
    const handleAccountNumberChange = (event) => {
        if (!isCuentaRut) {
            setAccountNumber(event.target.value)
        }
    }
    const handleAccountOwnerPersonalIdChange = (event) => {
        setAccountOwnerPersonalId(event.target.value)
        if (isCuentaRut) {
            replicateCuentaRut(event.target.value)
        }
    }


    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <SwitchWrapper
                            name="is_default"
                            label={t('Is default')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <SwitchWrapper
                            name="is_intermediary"
                            label={t('Is intermediary')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={6} md={3}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={bank_accounts.status}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={6} md={3}>
                        {filteredCountryList ? (
                            <GenericWrapper
                                name={'bank_country'}
                                autoForceWidth={false}
                                label={t('Country')}
                                fieldMetadata={{
                                    "type": "choice",
                                    "required": true,
                                    "read_only": false,
                                    "label": "Country",
                                    "max_length": null,
                                    "allow_null": false,
                                    "choices": filteredCountryList,
                                }}
                                onChange={event => setSelectedCountry(event.target.value)}
                                value={selectedCountry}
                                disabled={!hasPermissions}
                                WrappedComponent={EditField}
                            />) : <LinearProgress />}
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <TextfieldWrapper
                            name="bank_address"
                            label={t('Bank address')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={6}>
                        <GenericWrapper
                            name="bank_id"
                            autoForceWidth={false}
                            label={t('Bank')}
                            fieldMetadata={{
                                "type": "choice",
                                "required": true,
                                "read_only": false,
                                "max_length": null,
                                "allow_null": false,
                                "choices": countryBankList ?? {},
                            }}
                            disabled={!hasPermissions || countryBankList === null}
                            WrappedComponent={EditField}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="account_owner"
                            label={t('Account holder')}
                            type="text"
                            inputProps={{
                                maxLength: 128
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <GenericWrapper
                            name="account_owner_personal_id"
                            label={selectedCountry === 'CL' ? t('RUT') : t('Document number')}
                            placeholder={selectedCountry === 'CL' ? 'xxxxxxxx-x' : ''}
                            type="text"
                            inputProps={{
                                maxLength: 32
                            }}
                            disabled={!hasPermissions}
                            fullWidth
                            onChange={handleAccountOwnerPersonalIdChange}
                            WrappedComponent={TextField}
                        />
                    </Grid>


                    <Grid item xs={12} md={3}>
                        <GenericWrapper
                            name="account_number"
                            label={t('Account number')}
                            type="text"
                            disabled={!hasPermissions || isCuentaRut}
                            fullWidth
                            onChange={handleAccountNumberChange}
                            value={accountNumber}
                            WrappedComponent={TextField}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <GenericWrapper
                            name={'account_type'}
                            autoForceWidth={false}
                            label={t('Account type')}
                            fieldMetadata={{
                                "type": selectedCountry === 'CL' ? "choice" : "text",
                                "required": true,
                                "read_only": false,
                                "label": "Country",
                                "max_length": null,
                                "allow_null": false,
                                "choices": Object.fromEntries(standardAccountTypes.map((type) => [type, type])),
                            }}
                            disabled={!hasPermissions}
                            onChange={handleAccountTypeChange}
                            WrappedComponent={EditField}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="notification_email"
                            label={t('Notification email')}
                            type="email"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="swift_bic"
                            label={t('SWIFT/BIC')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="iban"
                            label={t('IBAN')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate(`/merchants/${merchant_id}/bank-accounts`)}
                                startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
