import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import {
    Chip,
    Typography
} from '@mui/material'
import CurrencyValue from '../../../TableUI/CurrencyValue'
import TableActions from '../../../TableUI/TableActions'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useWithdrawalDeleteDialog } from '../WithdrawalDelete'


const styles = () => ({
    root: {
        width: '100%',
        overflowX: "auto",
    }, table: {
        minWidth: 650,
    }
})

export default function InProcessWithdrawalsTable({
    data,
    working,
    handleConfirm,
    handleCancel,
    handleReject,
    hasPermissions
}) {
    const { t } = useTranslation()
    const [openDeleteDialog, DeleteDialog] = useWithdrawalDeleteDialog(handleReject)

    return (
        <>
            <Typography
                variant='h5'
                style={{
                    textAlign: 'center',
                    marginTop: '1rem',
                    marginBottom: '1rem'
                }}>{t('Group ID')}: {data.group_id}</Typography>

            <TableContainer className={styles.root}>
                <Table className={styles.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Nº')}</TableCell>
                            <TableCell>{t('Invoice Code')}</TableCell>
                            <TableCell>{t('Merchant')}</TableCell>
                            <TableCell>{t('Bank')}</TableCell>
                            <TableCell>{t('Account')}</TableCell>
                            <TableCell>{t('Beneficiary')}</TableCell>
                            <TableCell align="right">{t('Amount')}</TableCell>
                            <TableCell align="center">{t('Status')}</TableCell>
                            {hasPermissions && (<TableCell>&nbsp;</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.withdrawals ? data.withdrawals.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography noWrap>{idx + 1}</Typography>
                                </TableCell>
                                <TableCell>{row.invoice_code}</TableCell>
                                <TableCell>{row.merchant.username}</TableCell>
                                <TableCell>{row.merchant.default_bank_account.bank.name}</TableCell>
                                <TableCell>{row.merchant.default_bank_account.account_number}</TableCell>
                                <TableCell>{row.merchant.default_bank_account.account_owner}</TableCell>
                                <TableCell align="right">
                                    <CurrencyValue amount={row.withdrawable_amount} currency={row.currency} />
                                </TableCell>
                                <TableCell align="center">
                                    <Chip label={row.status_.toUpperCase()} color="info" variant="outlined" />
                                </TableCell>
                                {hasPermissions && (<TableCell>
                                    <TableActions actions={[
                                        {
                                            id: `confirm-${row.id}`,
                                            handleClick: () => handleConfirm(row.id),
                                            icon: <CheckCircleOutlineIcon />,
                                            title: 'Confirmar Pago'
                                        },
                                        {
                                            id: `cancel-${row.id}`,
                                            handleClick: () => handleCancel(row.id),
                                            icon: <RestartAltIcon />,
                                            title: 'Cancelar Pago'
                                        },
                                        {
                                            id: `failed-${row.id}`,
                                            handleClick: () => (openDeleteDialog(row.id)),
                                            icon: <HighlightOffIcon />,
                                            title: 'Eliminar Pago'
                                        }
                                    ]}
                                        disabled={row.status_ !== "In Process"} />
                                </TableCell>)}
                            </TableRow>
                        )) : <TableRow>
                            {
                                <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
            <DeleteDialog />
        </>
    )
}
