import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'

import TableBackdrop from '../../../TableUI/TableBackdrop'
import {
    Backdrop,
    CircularProgress,
    Link,
    Typography
} from '@mui/material'
import TableActions from '../../../TableUI/TableActions'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ListIcon from '@mui/icons-material/List'
import DownloadIcon from '@mui/icons-material/Download'
import CurrencyValue from '../../../TableUI/CurrencyValue'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useNavigate } from 'react-router-dom'


const styles = () => ({
    root: {
        width: '100%',
        overflowX: "auto",
    }, table: {
        minWidth: 650,
    }
})

export default function GroupedInProcessWithdrawalsTable({
    data,
    loading = false,
    working,
    setCurrentData,
    handleCancel,
    handleDownload,
    hasPermissions
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()



    return (
        <>
            <Backdrop
                sx={{ color: '#fff', fontWeight: 800, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={working}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <TableContainer className={styles.root}>
                <Table className={styles.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Nº')}</TableCell>
                            <TableCell>{t('Group ID')}</TableCell>
                            <TableCell align="center">{t('Nº Withdrawas')}</TableCell>
                            <TableCell align="right">{t('Total')}</TableCell>
                            <TableCell align="center">{t('Currency')}</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && data.length ? data.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography noWrap>{idx + 1}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Link style={{ cursor: 'pointer' }} onClick={() => setCurrentData(row)}>{row.group_id}</Link>
                                </TableCell>
                                <TableCell align="center">{row.withdrawals?.length}</TableCell>
                                <TableCell align="right">
                                    <CurrencyValue amount={row.total} currency={row.currency} />
                                </TableCell>
                                <TableCell align="center">{row.currency}</TableCell>

                                <TableCell>
                                    <TableActions actions={[
                                        {
                                            id: `show-${row.id}`,
                                            handleClick: () => (setCurrentData(row)),
                                            icon: <ListIcon />,
                                            title: 'Listado de pagos pendientes',
                                            show: hasPermissions
                                        },
                                        {
                                            id: `download-${row.id}`,
                                            handleClick: () => (handleDownload(row.group_id)),
                                            icon: <DownloadIcon />,
                                            title: 'Descargar Matriz Banco Estado',
                                            show: hasPermissions
                                        },
                                        {
                                            id: `cancel-${row.id}`,
                                            handleClick: () => handleCancel(row.group_id),
                                            icon: <RestartAltIcon />,
                                            title: 'Cancelar todos los Pagos Pendientes',
                                            show: hasPermissions
                                        },
                                        {
                                            id: `upload-${row.id}`,
                                            handleClick: () => navigate(`/upload-withdrawals/${row.group_id}`),
                                            icon: <CloudUploadIcon />,
                                            title: 'Procesar Respuesta Banco',
                                            show: hasPermissions
                                        }
                                    ]} />
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
