import { objectMap } from "utils/jsUtils"

function translateChoices(choices, t) {
    return objectMap(({ value }) => t(value), choices)
}

export function translateChoicesInFieldInfo(fieldInfo, translatedChoiceFieldsCalc, t) {
    return objectMap(({ key, value }) => {
        if (translatedChoiceFieldsCalc.has(key)) {
            return { ...value, choices: translateChoices(value.choices, t) }
        }
        return value
    }, fieldInfo)
}