import { useState } from "react"

import { useTranslation } from "react-i18next"

import { IconButton, Button, Menu, MenuItem, ButtonGroup, Stack, Box } from "@mui/material"

import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import ClearIcon from "@mui/icons-material/Clear"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"

import { objectFilter, objectMap, objectEqual } from "utils/jsUtils"

import AppFilters from "components/AppFilters"

import EditField from "./EditFields"
import PopoverButton from "./PopoverButton"


/* some lookup expressions:
 *       - exact
 *       - contains
 *       - icontains
 *       - in
 *       - gt
 *       - gte
 *       - lt
 *       - lte
 *       - isnull
 */

function sumarizedFieldFiltersInfo(metadata) {
    const field_info = metadata.field_info
    const filter_fields = metadata.filter_fields

    const filteredFields = objectFilter(
        ({ key }) => filter_fields.hasOwnProperty(key),
        field_info,
    )

    const fieldInfoWithFilters = objectMap(
        ({ key, value }) => {
            const filter_info = filter_fields[key]
            return { ...value, filter_info: filter_info }
        },
        filteredFields,
    )

    return Object.entries(fieldInfoWithFilters).map(
        ([key, value]) => {
            return { field: key, ...value }
        }
    )
}


const lookupLabels = {
    exact: "is exactly",
    contains: "contains",
    icontains: "contains (case insensitive)",
    in: "one of",
    gt: "greater than",
    gte: "greater than or equal to",
    lt: "less than",
    lte: "less than or equal to",
    isnull: "is null",
}


function flattenFilters(filtersInfo) {
    return filtersInfo.map((rawFilterInfo) => {
        return rawFilterInfo.filter_info.lookup_exprs
            .filter((lookup_expr) => !lookup_expr.startsWith("date")) // TO FIX: date only filters are not implemented yet
            .map((lookup_expr) => {
                const getParam = rawFilterInfo.field + (lookup_expr === "exact" ? "" : `__${lookup_expr}`)
                return {
                    label: `${rawFilterInfo.label} ${lookupLabels[lookup_expr] ?? lookup_expr}`,
                    fieldLabel: rawFilterInfo.label,
                    lookupExpr: lookup_expr,
                    lookupExprLabel: lookupLabels[lookup_expr] ?? lookup_expr,
                    type: rawFilterInfo.type,
                    choices: rawFilterInfo.choices,
                    getParam: getParam,
                    initialValue: "",
                    max_length: rawFilterInfo.max_length,
                }
            })
    }).flat()
}


function GenericFilter({ filterInfo, onRemove = () => null, onChange = () => null }) {
    const { t } = useTranslation()
    return <Stack direction="row" sx={{ padding: "0.3rem" }}>
        <EditField
            fieldMetadata={{ ...filterInfo, label: `${t(filterInfo.fieldLabel)} ${t(filterInfo.lookupExprLabel)}` }}
            onChange={onChange}
            size="small"
        />
        <IconButton onClick={onRemove}>
            <DeleteIcon />
        </IconButton>
    </Stack>
}


export default function GenericFilters({ metadata, setGetParams = (getParams) => null }) {
    const { t } = useTranslation()

    const [appliedGetParams, setAppliedGetParams] = useState({})
    function applyParams() {
        setAppliedGetParams(localGetParams)
        setGetParams(localGetParams)
    }

    const [localGetParams, setLocalGetParams] = useState({})

    function addGetParam(key, value) {
        setLocalGetParams({ ...localGetParams, [key]: value })
    }

    function removeGetParam(key) {
        setLocalGetParams(objectFilter(
            ({ key: k }) => k !== key,
            localGetParams,
        ))
    }

    function setGetParam(key, value) {
        setLocalGetParams({ ...localGetParams, [key]: value })
    }

    const rawFiltersInfo = sumarizedFieldFiltersInfo(metadata)
    const flatFilters = flattenFilters(rawFiltersInfo)

    const [activeFilters, setActiveFilters] = useState([])

    function makeOnRemoveFilter(filterInfo) {
        return () => {
            setActiveFilters(activeFilters.filter((filter) => filter !== filterInfo))
            removeGetParam(filterInfo.getParam)
        }
    }

    function AddButton({ onClick }) {
        return <Button variant="text" onClick={onClick}> <AddIcon />{t("Add filter")}</Button>
    }

    function insertFilter(filterInfo) {
        addGetParam(filterInfo.getParam, filterInfo.initialValue)
        setActiveFilters([...activeFilters, filterInfo])
    }

    function FiltersMenu({ onClose, ...otherProps }) {
        return <Menu {...otherProps} onClose={onClose}>
            {flatFilters
                .filter(
                    (filterInfo) => !activeFilters.map((filter) => filter.label).includes(filterInfo.label)
                )
                .map((filterInfo, idx) =>
                    <MenuItem key={idx} onClick={() => {
                        insertFilter(filterInfo)
                        onClose()
                    }}>
                        <Box sx={{ fontWeight: "medium" }}>{`${t(filterInfo.fieldLabel)}`}</Box>
                        <ArrowRightIcon sx={{ mx: 1 }} />
                        <Box sx={{ color: "text.secondary" }}>{t(filterInfo.lookupExprLabel)}</Box>
                    </MenuItem>
                )}
        </Menu>
    }

    return <Box margin="1rem"><AppFilters Filters={<Stack
        direction="column"
        sx={{
            padding: "1rem"
        }}
        spacing="1rem"
    >
        <Box spacing="0.5rem" sx={{
            padding: "0.1rem",
        }}>
            {
                activeFilters.map((filterInfo, idx) => <GenericFilter
                    key={idx}
                    filterInfo={filterInfo}
                    onRemove={makeOnRemoveFilter(filterInfo)}
                    onChange={(e) => setGetParam(filterInfo.getParam, e.target.value)}
                />)
            }
            < PopoverButton ButtonComponent={AddButton} PopoverComponent={FiltersMenu} >
                {
                    flatFilters.map((filterInfo, idx) =>
                        <MenuItem key={idx} onClick={() => insertFilter(filterInfo)}>
                            {filterInfo.label}
                        </MenuItem>
                    )
                }
            </PopoverButton >
        </Box>
        <ButtonGroup>
            <Button
                variant="outlined"
                onClick={() => {
                    setLocalGetParams({})
                    setActiveFilters([])
                    if (!objectEqual(appliedGetParams, {})) {
                        setGetParams({})
                        setAppliedGetParams({})
                    }
                }}
                disabled={activeFilters.length === 0}
            >
                <ClearIcon />
                {t("Clear filters")}
            </Button>
            <Button
                variant="contained"
                disabled={objectEqual(appliedGetParams, localGetParams)}
                onClick={applyParams}
            >
                <FilterAltIcon />
                {t("Apply filters")}
            </Button>
        </ButtonGroup>
    </Stack >} /></Box>
}