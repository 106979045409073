import { useState, useEffect } from 'react'

import { Badge, Card, CardContent, Chip, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../../Misc/PageTitle'
import { toast } from 'react-toastify'
import useIsMounted from '../../../../hooks/useIsMounted'
import ToolsStack from '../../../Misc/ToolsStack'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import InProcessWithdrawalsTable from "./InProcessWithdrawalsTable";
import { WITHDRAWALS__COMPLETE } from '../../../../enums/Caps'
import useHasPermissions from '../../../../hooks/useHasPermissions'
import GroupedInProcessWithdrawalsTable from "./GroupedInProcessWithdrwalsTable";
import { useDownloader } from 'utils/backofficeUtils'


export default function ActiveWithdrawals() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const navigate = useNavigate()

    const [data, setData] = useState([])

    const [currentData, setCurrentData] = useState()

    const [loading, setLoading] = useState(true)

    const downloader = useDownloader()

    function handleCancel(group_id) {
        api.delete(`/internal/withdrawals/grouped/${group_id}/`)
            .then(() => {
                toast.success(t('Group cancelled successfully'))
                setData(data.filter(d => d.group_id !== group_id))
            }
            ).catch(() => {
                toast.error(t('Something went wrong'))
            })
    }

    const handleDownload = (group_id) => {
        downloader(`/internal/withdrawals/grouped/${group_id}/bulk-payout-file/`, { exportable_type: "bancoestado-xlsx" })
    }

    const handleCancelSingle = (id) => {
        api.post(`/internal/withdrawals/${id}/ungroup/`)
            .then(() => {
                toast.success(t('Withdrawal cancelled successfully'))
                setCurrentData({ ...currentData, withdrawals: currentData.withdrawals.filter(w => w.id !== id) })
            }
            ).catch(() => {
                toast.error(t('Something went wrong'))
            })
    }

    const handleConfirm = (id) => {
        api.post(`/internal/withdrawals/${id}/complete/`)
            .then(() => {
                toast.success(t('Withdrawal confirmed successfully'))
                setCurrentData({ ...currentData, withdrawals: currentData.withdrawals.filter(w => w.id !== id) })
            }
            ).catch(() => {
                toast.error(t('Something went wrong'))
            })
    }

    const handleReject = (id) => {
        setCurrentData({ ...currentData, withdrawals: currentData.withdrawals.filter(w => w.id !== id) })
    }

    useEffect(() => {
        api.get('/internal/withdrawals/grouped/')
            .then(({ data: { data } }) => {
                if (!isMounted()) {
                    return
                }
                setLoading(true)
                setData(data)
                console.log(data)
            })
            .catch(() => {
                toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))
    }, [t, isMounted, api])

    return (
        <Card>
            <CardContent>
                <PageTitle title={t('In process payouts')} />

                <ToolsStack justifyContent="end">
                    <Stack direction="row" spacing={3}>
                        {currentData && (<Chip
                            label={currentData.group_id}
                            variant="outlined"
                            color="info"
                            onDelete={() => setCurrentData(null)}
                        />)}

                        <Badge color="info">
                            <Chip
                                label="Pending"
                                variant="outlined"
                                color="info"
                                onClick={() => navigate('/pending-payouts')}
                            />
                        </Badge>
                    </Stack>
                </ToolsStack>

                {!currentData && (<GroupedInProcessWithdrawalsTable
                    data={data}
                    loading={loading}
                    isPending={true}
                    setCurrentData={setCurrentData}
                    handleDownload={handleDownload}
                    hasPermissions={hasPermissions(WITHDRAWALS__COMPLETE)}
                    handleCancel={handleCancel}
                />)}

                {currentData && (<InProcessWithdrawalsTable
                    data={currentData}
                    handleConfirm={handleConfirm}
                    handleReject={handleReject}
                    handleCancel={handleCancelSingle}
                    hasPermissions={hasPermissions(WITHDRAWALS__COMPLETE)}
                />)}

            </CardContent>
        </Card>
    )
}
