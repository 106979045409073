import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from 'react-router-dom'

import React from 'react'
import AuthRouter from './AuthRouter'
import Page from '../components/Pages/Page'
import Profile from '../components/Pages/Profile/Profile'
import Merchants from '../components/Pages/Merchants/Merchants'
import MerchantCreate from '../components/Pages/Merchants/MerchantCreate'
import MerchantsList from '../components/Pages/Merchants/MerchantsList'
import MerchantEdit from '../components/Pages/Merchants/MerchantEdit'
import Transactions from '../components/Pages/Transactions/Transactions'
import TransactionsList from '../components/Pages/Transactions/TransactionsList'
import Dashboard from '../components/Pages/Dashboard/Dashboard'
import TransactionDetails from '../components/Pages/Transactions/TransactionDetails'
import Methods from '../components/Pages/Methods/Methods'
import MethodsList from '../components/Pages/Methods/MethodsList'
import Contacts from '../components/Pages/Merchants/Contacts/Contacts'
import Addresses from '../components/Pages/Merchants/Addresses/Addresses'
import MerchantMethods from '../components/Pages/Merchants/Methods/Methods'
import MerchantSubmethodsList from 'components/Pages/Merchants/Submethods/SubmethodsList'
import MerchantSpreads from '../components/Pages/Merchants/Spreads/Spreads'
import AccountConfig from '../components/Pages/Merchants/AccountConfig/AccountConfig'
import BankAccountsList from '../components/Pages/Merchants/BankAccounts/BankAccountsList'
import BankAccounts from '../components/Pages/Merchants/BankAccounts/BankAccounts'
import BankAccountCreate from '../components/Pages/Merchants/BankAccounts/BankAccountCreate'
import BankAccountEdit from '../components/Pages/Merchants/BankAccounts/BankAccountEdit'
import SpreadsList from '../components/Pages/Merchants/Spreads/SpreadsList'
import SpreadCreate from '../components/Pages/Merchants/Spreads/SpreadCreate'
import SpreadEdit from '../components/Pages/Merchants/Spreads/SpreadEdit'
import GlobalSpreads from 'components/Pages/Spreads/Spreads'
import GlobalSpreadsList from 'components/Pages/Spreads/SpreadsList'
import ActiveWithdrawals from "../components/Pages/Withdrawals/InProcess/ActiveWithdrawals";
import Withdrawals from '../components/Pages/Withdrawals/Withdrawals'
import WithdrawalsList from '../components/Pages/Withdrawals/WithdrawalsList'
import MethodCreate from '../components/Pages/Methods/MethodCreate'
import MethodEdit from '../components/Pages/Methods/MethodEdit'
import Users from '../components/Pages/Users/Users'
import UsersList from '../components/Pages/Users/UsersList'
import UserCreate from '../components/Pages/Users/UserCreate'
import UserEdit from '../components/Pages/Users/UserEdit'
import Miscellaneous from '../components/Pages/Merchants/Miscellaneous/Miscellaneous'
import MiscellaneousList from '../components/Pages/Merchants/Miscellaneous/MiscellaneousList'
import MiscellaneousCreate from '../components/Pages/Merchants/Miscellaneous/MiscellaneousCreate'
import MiscellaneousEdit from '../components/Pages/Merchants/Miscellaneous/MiscellaneousEdit'
import MethodFees from '../components/Pages/Methods/Fees/MethodFees'
import MethodFeesList from '../components/Pages/Methods/Fees/MethodFeesList'
import SubMethods from 'components/Pages/Methods/SubMethods/SubMethods'
import SubMethodsList from 'components/Pages/Methods/SubMethods/SubMethodsList'
import SubmethodFees from 'components/Pages/Methods/SubMethods/Fees/SubmethodFees'
import SubmethodFeesList from 'components/Pages/Methods/SubMethods/Fees/SubmethodFeesList'
import MethodProviders from '../components/Pages/MethodProviders/MethodProviders'
import MethodProvidersList from '../components/Pages/MethodProviders/MethodProvidersList'
import MethodProviderCreate from '../components/Pages/MethodProviders/MethodProviderCreate'
import MethodProviderEdit from '../components/Pages/MethodProviders/MethodProviderEdit'
import MethodsRequested from '../components/Pages/Methods/MethodsRequested/MethodsRequested'
import MethodsRequestedList from '../components/Pages/Methods/MethodsRequested/MethodsRequestedList'
import Countries from '../components/Pages/Settings/Countries/Countries'
import CountriesList from '../components/Pages/Settings/Countries/CountriesList'
import CountryEdit from '../components/Pages/Settings/Countries/CountryEdit'
import ToPayPayouts from '../components/Pages/ToPayPayouts/ToPayPayouts'
import ToPayPayoutsList from '../components/Pages/ToPayPayouts/ToPayPayoutsList'
import Settlements from '../components/Pages/Settlements/Settlements'
import SettlementsList from '../components/Pages/Settlements/SettlementsList'
import ContactsList from '../components/Pages/Merchants/Contacts/ContactsList'
import AddressesList from '../components/Pages/Merchants/Addresses/AddressesList'
import AddressCreate from '../components/Pages/Merchants/Addresses/AddressCreate'
import AddressEdit from 'components/Pages/Merchants/Addresses/AddressEdit'
import ContactCreate from '../components/Pages/Merchants/Contacts/ContactCreate'
import ContactEdit from '../components/Pages/Merchants/Contacts/ContactEdit'
import MerchantSubmerchants from 'components/Pages/Merchants/Submerchants/Submerchants'
import MerchantSubmerchantsList from 'components/Pages/Merchants/Submerchants/SubmerchantsList'
import Charts from '../components/Pages/Charts/Charts'
import Sales from '../components/Pages/Charts/Sales/Sales'
import Merchant from '../components/Pages/Charts/Merchant/Merchant';
import Cashouts from '../components/Pages/Cashouts/Cashouts'
import Pending from '../components/Pages/Cashouts/Pending/Pending'
import Active from '../components/Pages/Cashouts/Active/Active'
import CashoutsList from '../components/Pages/Cashouts/CashoutsList'
import FileUpload from '../components/Pages/Cashouts/FileUpload/FileUpload'
import FileUploadWithdrawals from '../components/Pages/Withdrawals/FileUpload/FileUpload'
import useAuth from '../hooks/useAuth'
import RestrictedRouter from './RestrictedRouter'
import {
    ACCOUNT_CONFIG__UPDATE,
    ACCOUNT_CONFIG__VIEW,
    BANK_ACCOUNTS__CREATE,
    BANK_ACCOUNTS__DELETE,
    BANK_ACCOUNTS__UPDATE,
    BANK_ACCOUNTS__VIEW,
    CASHOUTS__DELETE,
    CASHOUTS__PROCESS,
    CASHOUTS__VIEW,
    COUNTRY_SPREADS__CREATE,
    COUNTRY_SPREADS__DELETE,
    COUNTRY_SPREADS__UPDATE,
    COUNTRY_SPREADS__VIEW,
    MERCHANTS__CREATE,
    MERCHANTS__UPDATE,
    MERCHANTS__VIEW,
    MERCHANTS__VIEW_BALANCE,
    MERCHANT_CONTACTS__CREATE,
    MERCHANT_CONTACTS__DELETE,
    MERCHANT_CONTACTS__UPDATE,
    MERCHANT_CONTACTS__VIEW,
    MERCHANT_PAYMENT_METHODS__UPDATE,
    MERCHANT_PAYMENT_METHODS__VIEW,
    METHOD_PROVIDERS__CREATE,
    METHOD_PROVIDERS__UPDATE,
    METHOD_PROVIDERS__VIEW,
    MISCELLANEOUS__CREATE,
    MISCELLANEOUS__DELETE,
    MISCELLANEOUS__UPDATE,
    MISCELLANEOUS__VIEW,
    PAYMENT_METHODS__CREATE,
    PAYMENT_METHODS__FEES,
    PAYMENT_METHODS__UPDATE,
    PAYMENT_METHODS__VIEW,
    TRANSACTIONS__CHARGEBACK,
    TRANSACTIONS__COMPLETE,
    TRANSACTIONS__HOLD,
    TRANSACTIONS__REFUND,
    TRANSACTIONS__VIEW,
    USERS__CREATE,
    USERS__UPDATE,
    USERS__VIEW,
    WITHDRAWALS__COMPLETE,
    WITHDRAWALS__DELETE,
    WITHDRAWALS__VIEW,
    ADDRESSES__VIEW,
    ADDRESSES__CREATE,
    ADDRESSES__UPDATE,
    ADDRESSES__DELETE
} from '../enums/Caps'
import Documents from 'components/Pages/Documents/Documents'
import DocumentsList from 'components/Pages/Documents/DocumentsList'
import DocumentVersions from 'components/Pages/Documents/Versions/Versions'
import DocumentVersionsList from 'components/Pages/Documents/Versions/VersionsList'
import DocumentFilesList from 'components/Pages/Documents/Versions/Files/FilesList'


export default function AppRouter() {
    const { auth } = useAuth()

    const authRoutes = AuthRouter()

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    {!auth?.user && (<Route path="/auth">
                        {authRoutes}
                    </Route>)}

                    {auth?.user && (<Route path="/" element={<Page />}>
                        <Route path="/" element={<Dashboard />} />

                        <Route element={<RestrictedRouter allowCaps={[
                            TRANSACTIONS__VIEW,
                            TRANSACTIONS__COMPLETE,
                            TRANSACTIONS__CHARGEBACK,
                            TRANSACTIONS__HOLD,
                            TRANSACTIONS__REFUND
                        ]} />}>
                            <Route path="/transactions" element={<Transactions />}>
                                <Route index element={<TransactionsList />} />
                                <Route path="details/:transactionId" element={<TransactionDetails />} />
                            </Route>
                        </Route>

                        <Route element={<RestrictedRouter allowCaps={[
                            MERCHANTS__VIEW,
                            MERCHANTS__CREATE,
                            MERCHANTS__UPDATE,
                            ACCOUNT_CONFIG__VIEW,
                            ACCOUNT_CONFIG__UPDATE,
                            MERCHANT_PAYMENT_METHODS__VIEW,
                            MERCHANT_PAYMENT_METHODS__UPDATE,
                            BANK_ACCOUNTS__VIEW,
                            BANK_ACCOUNTS__CREATE,
                            BANK_ACCOUNTS__UPDATE,
                            BANK_ACCOUNTS__DELETE,
                            COUNTRY_SPREADS__VIEW,
                            COUNTRY_SPREADS__CREATE,
                            COUNTRY_SPREADS__UPDATE,
                            COUNTRY_SPREADS__DELETE,
                            MISCELLANEOUS__VIEW,
                            MISCELLANEOUS__CREATE,
                            MISCELLANEOUS__UPDATE,
                            MISCELLANEOUS__DELETE,
                            MERCHANT_CONTACTS__VIEW,
                            MERCHANT_CONTACTS__CREATE,
                            MERCHANT_CONTACTS__UPDATE,
                            MERCHANT_CONTACTS__DELETE,
                            MERCHANTS__VIEW_BALANCE
                        ]} />}>
                            <Route path="/merchants" element={<Merchants />}>
                                <Route index element={<MerchantsList />} />

                                <Route element={<RestrictedRouter allowCaps={[MERCHANTS__CREATE]} />}>
                                    <Route path="create" element={<MerchantCreate />} />
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[MERCHANTS__VIEW, MERCHANTS__UPDATE]} />}>
                                    <Route path=":userId/edit" element={<MerchantEdit />} />
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    ACCOUNT_CONFIG__VIEW,
                                    ACCOUNT_CONFIG__UPDATE
                                ]} />}>
                                    <Route path=":userId/config" element={<AccountConfig />} />
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    BANK_ACCOUNTS__VIEW,
                                    BANK_ACCOUNTS__CREATE,
                                    BANK_ACCOUNTS__UPDATE,
                                    BANK_ACCOUNTS__DELETE,
                                ]} />}>
                                    <Route path=":userId/bank-accounts" element={<BankAccounts />}>
                                        <Route index element={<BankAccountsList />} />
                                        <Route element={<RestrictedRouter allowCaps={[
                                            BANK_ACCOUNTS__CREATE
                                        ]} />}>
                                            <Route path="create" element={<BankAccountCreate />} />
                                        </Route>

                                        <Route element={<RestrictedRouter allowCaps={[
                                            BANK_ACCOUNTS__VIEW,
                                            BANK_ACCOUNTS__UPDATE,
                                        ]} />}>
                                            <Route path=":accountId/edit" element={<BankAccountEdit />} />
                                        </Route>
                                    </Route>
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    COUNTRY_SPREADS__VIEW,
                                    COUNTRY_SPREADS__CREATE,
                                    COUNTRY_SPREADS__UPDATE,
                                    COUNTRY_SPREADS__DELETE,
                                ]} />}>
                                    <Route path=":userId/spreads" element={<MerchantSpreads />}>
                                        <Route index element={<SpreadsList />} />

                                        <Route element={<RestrictedRouter allowCaps={[
                                            COUNTRY_SPREADS__CREATE,
                                        ]} />}>
                                            <Route path="create" element={<SpreadCreate />} />
                                        </Route>

                                        <Route element={<RestrictedRouter allowCaps={[
                                            COUNTRY_SPREADS__VIEW,
                                            COUNTRY_SPREADS__UPDATE,
                                        ]} />}>
                                            <Route path=":spreadId/edit" element={<SpreadEdit />} />
                                        </Route>
                                    </Route>
                                </Route>

                                <Route path=":userId/miscellaneous" element={<Miscellaneous />}>
                                    <Route index element={<MiscellaneousList />} />
                                    <Route path="create" element={<MiscellaneousCreate />} />
                                    <Route path=":miscId/edit" element={<MiscellaneousEdit />} />
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    MERCHANT_CONTACTS__VIEW,
                                    MERCHANT_CONTACTS__CREATE,
                                    MERCHANT_CONTACTS__UPDATE,
                                    MERCHANT_CONTACTS__DELETE
                                ]} />}>
                                    <Route path=":userId/contacts" element={<Contacts />}>
                                        <Route index element={<ContactsList />} />
                                        <Route element={<RestrictedRouter allowCaps={[
                                            MERCHANT_CONTACTS__CREATE
                                        ]} />}>
                                            <Route path="create" element={<ContactCreate />} />
                                        </Route>

                                        <Route element={<RestrictedRouter allowCaps={[
                                            MERCHANT_CONTACTS__UPDATE
                                        ]} />}>
                                            <Route path=":contactId/edit" element={<ContactEdit />} />
                                        </Route>
                                    </Route>
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    ADDRESSES__VIEW,
                                    ADDRESSES__CREATE,
                                    ADDRESSES__UPDATE,
                                    ADDRESSES__DELETE
                                ]} />}>
                                    <Route path=":userId/addresses" element={<Addresses />}>
                                        <Route index element={<AddressesList />} />
                                        <Route element={<RestrictedRouter allowCaps={[
                                            ADDRESSES__CREATE
                                        ]} />}>
                                            <Route path="create" element={<AddressCreate />} />
                                        </Route>
                                        <Route element={<RestrictedRouter allowCaps={[
                                            ADDRESSES__UPDATE
                                        ]} />}>
                                            <Route path=":addressId/edit" element={<AddressEdit />} />
                                        </Route>
                                    </Route>
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    MERCHANTS__UPDATE,
                                ]} />}>
                                    <Route path=":userId/submerchants" element={<MerchantSubmerchants />}>
                                        <Route index element={<MerchantSubmerchantsList />} />
                                    </Route>
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    MERCHANT_PAYMENT_METHODS__VIEW, MERCHANT_PAYMENT_METHODS__UPDATE
                                ]} />}>
                                    <Route path=":userId/methods" element={<MerchantMethods />} />
                                </Route>
                                <Route element={<RestrictedRouter allowCaps={[
                                    MERCHANT_PAYMENT_METHODS__VIEW, MERCHANT_PAYMENT_METHODS__UPDATE
                                ]} />}>
                                    <Route path=":userId/submethods" element={<MerchantSubmethodsList />} />
                                </Route>
                            </Route>
                        </Route>

                        <Route element={<RestrictedRouter allowCaps={[
                            WITHDRAWALS__VIEW,
                            WITHDRAWALS__COMPLETE,
                            WITHDRAWALS__DELETE
                        ]} />}>
                            <Route path="/pending-payouts" element={<Withdrawals />}>
                                <Route index element={<WithdrawalsList />} />
                            </Route>
                            <Route path="/in-process-payouts" element={<ActiveWithdrawals />}>
                                <Route index element={<WithdrawalsList />} />
                            </Route>
                            <Route path="/payment-list" element={<ToPayPayouts />}>
                                <Route index element={<SettlementsList />} />
                            </Route>

                            <Route path="/to-pay-payouts" element={<Settlements />}>
                                <Route index element={<ToPayPayoutsList />} />
                            </Route>
                            <Route path="/upload-withdrawals/:groupId" element={<FileUploadWithdrawals />} />
                        </Route>

                        <Route element={<RestrictedRouter allowCaps={[
                            PAYMENT_METHODS__VIEW,
                            PAYMENT_METHODS__CREATE,
                            PAYMENT_METHODS__UPDATE,
                            PAYMENT_METHODS__FEES,
                        ]} />}>
                            <Route path="/payment-methods" element={<Methods />}>
                                <Route index element={<MethodsList />} />
                                <Route element={<RestrictedRouter allowCaps={[
                                    PAYMENT_METHODS__CREATE,
                                ]} />}>
                                    <Route path="create" element={<MethodCreate />} />
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    PAYMENT_METHODS__VIEW,
                                    PAYMENT_METHODS__UPDATE,
                                ]} />}>
                                    <Route path=":methodId/edit" element={<MethodEdit />} />
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    PAYMENT_METHODS__VIEW,
                                    PAYMENT_METHODS__FEES,
                                ]} />}>
                                    <Route path=":methodId/fees" element={<MethodFees />}>
                                        <Route index element={<MethodFeesList />} />
                                    </Route>
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    PAYMENT_METHODS__VIEW,
                                ]} />}>
                                    <Route path=":methodId/submethods" element={<SubMethods />}>
                                        <Route index element={<SubMethodsList />} />
                                        <Route path=":submethodId/fees" element={<SubmethodFees />}>
                                            <Route index element={<SubmethodFeesList />} />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>

                        <Route element={<RestrictedRouter allowCaps={[
                            METHOD_PROVIDERS__VIEW,
                            METHOD_PROVIDERS__CREATE,
                            METHOD_PROVIDERS__UPDATE
                        ]} />}>
                            <Route path="/method-providers" element={<MethodProviders />}>
                                <Route index element={<MethodProvidersList />} />
                                <Route element={<RestrictedRouter allowCaps={[
                                    METHOD_PROVIDERS__CREATE,
                                ]} />}>
                                    <Route path="create" element={<MethodProviderCreate />} />
                                </Route>
                                <Route element={<RestrictedRouter allowCaps={[
                                    METHOD_PROVIDERS__VIEW,
                                    METHOD_PROVIDERS__UPDATE
                                ]} />}>
                                    <Route path=":providerId/edit" element={<MethodProviderEdit />} />
                                </Route>
                            </Route>
                        </Route>

                        <Route element={<RestrictedRouter allowCaps={[
                            MERCHANT_PAYMENT_METHODS__UPDATE
                        ]} />}>
                            <Route path="/methods-requested" element={<MethodsRequested />}>
                                <Route index element={<MethodsRequestedList />} />
                            </Route>
                        </Route>

                        <Route element={<RestrictedRouter allowCaps={[
                            TRANSACTIONS__VIEW
                        ]} />}>
                            <Route path="/charts" element={<Charts />}>
                                <Route path="sales" element={<Sales />} />
                                <Route path="traffic" element={<Merchant />} />
                            </Route>
                        </Route>

                        <Route element={<RestrictedRouter allowCaps={[
                            USERS__VIEW,
                            USERS__CREATE,
                            USERS__UPDATE
                        ]} />}>
                            <Route path="users" element={<Users />}>
                                <Route index element={<UsersList />} />

                                <Route element={<RestrictedRouter allowCaps={[
                                    USERS__CREATE,
                                ]} />}>
                                    <Route path="create" element={<UserCreate />} />
                                </Route>

                                <Route element={<RestrictedRouter allowCaps={[
                                    USERS__VIEW,
                                    USERS__UPDATE
                                ]} />}>
                                    <Route path=":userId/edit" element={<UserEdit />} />
                                </Route>
                            </Route>
                        </Route>

                        <Route element={<RestrictedRouter allowCaps={[
                            CASHOUTS__VIEW,
                            CASHOUTS__PROCESS,
                            CASHOUTS__DELETE
                        ]} />}>
                            <Route path="/cashouts" element={<Cashouts />}>
                                <Route index element={<CashoutsList />} />
                                <Route path="pending" element={<Pending />} />
                                <Route path="active" element={<Active />} />

                                <Route element={<RestrictedRouter allowCaps={[
                                    CASHOUTS__PROCESS,
                                ]} />}>
                                    <Route path="upload/:groupId" element={<FileUpload />} />
                                </Route>
                            </Route>
                        </Route>

                        <Route element={<RestrictedRouter allowCaps={[
                            COUNTRY_SPREADS__VIEW,
                            COUNTRY_SPREADS__UPDATE,
                        ]} />}>
                            <Route path="/spreads" element={<GlobalSpreads />}>
                                <Route index element={<GlobalSpreadsList />} />
                            </Route>
                        </Route>

                        <Route path="/settings">
                            <Route path="countries" element={<Countries />}>
                                <Route index element={<CountriesList />} />
                                {/*  <Route path="create" element={<MethodProviderCreate />} /> */}
                                <Route path=":countryId/edit" element={<CountryEdit />} />
                            </Route>
                        </Route>

                        <Route path="profile" element={<Profile />} />

                        <Route path="/documents" element={<Documents />}>
                            <Route index element={<DocumentsList />} />
                            <Route path=":documentId/versions" element={<DocumentVersions />}>
                                <Route index element={<DocumentVersionsList />} />
                                <Route path=":versionId/files" element={<DocumentFilesList />} />
                            </Route>
                        </Route>
                    </Route>)}

                    <Route path="*" element={<Navigate to={auth?.user ? '/' : '/auth/login'} />} />
                </Routes >
            </BrowserRouter>
        </div >

    )
}
