import { Stack } from '@mui/material'

import { ButtonGroup, Button } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { toast } from 'react-toastify'

import EditField from './EditFields'

import useIsMounted from 'hooks/useIsMounted'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function EditCell({ editCallback, fieldMeta, currentValue, valid = (value) => true }) {
    const [newValue, setNewValue] = useState(currentValue)
    const [awaiting, setAwaiting] = useState(false)

    const isMounted = useIsMounted()
    const { t } = useTranslation()

    function onApply() {
        if (!valid(newValue)) return

        editCallback(newValue).catch((e) => {
            if (!isMounted()) return
            switch (e.response.status) {
                case 400:
                    toast.error(`${t("Invalid value for column")} '${t(fieldMeta.label)}'`)
                    break
                default:
                    toast.error(t("There was an unexpected error"))
            }
            setNewValue(currentValue)
            setAwaiting(false)
        })

        setAwaiting(true)
    }

    const FinishButtons = <ButtonGroup size="small">
        <Button onClick={() => editCallback()} variant="outlined">
            {t("Cancel")}
        </Button>
        <Button onClick={onApply} variant="contained" color="success">
            {t("Save")}
        </Button>
    </ButtonGroup>

    return <Stack direction="column" spacing={0.5} alignItems="center" width="fit-content" >
        <EditField
            field={fieldMeta.field}
            error={!valid(newValue)}
            fieldMetadata={fieldMeta}
            value={newValue}
            // sx={{ width: 90 }}
            size="small"
            onChange={(e) => setNewValue(e.target.value)}
        />
        {awaiting ? <CircularProgress /> : FinishButtons}
    </Stack>
}
