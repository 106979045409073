import { Card, CardContent, CardHeader, Grid, Box, ButtonGroup, Button, Tooltip } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import KeyValue from '../../TableUI/KeyValue'

import { Stack } from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ReplayIcon from '@mui/icons-material/Replay'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import TransactionStatusModal from './TransactionStatusModal'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import { toast } from 'react-toastify'
import { status, ST_CHARGEBACK, ST_COMPLETED, ST_CREATED, ST_FAILED, ST_ONHOLD, ST_REFUND, ST_REJECTED } from '../../../enums/Transactions'
import ReactJson from 'react-json-view'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useIsMounted from '../../../hooks/useIsMounted'
import { TRANSACTIONS__CHARGEBACK, TRANSACTIONS__COMPLETE, TRANSACTIONS__HOLD, TRANSACTIONS__REFUND } from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions'


export default function TransactionDetails() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const api = useAxiosPrivate()

    const isMounted = useIsMounted()

    const [open, setOpen] = useState(false)

    const [loading, setLoading] = useState(true)

    const [transaction, setTransaction] = useState(null)

    const [otherProps, setOtherProps] = useState(null)

    const { transactionId } = useParams()

    const navigate = useNavigate()

    const handleClose = () => {
        setOtherProps(null)
        setOpen(false)
    }

    const handleComplete = () => {
        setOtherProps({
            title: t('Manually complete this transaction'),
            handleSubmit: (values) => {
                api.post(`/internal/transactions/${transactionId}/comments/`, { ...values, new_status: ST_COMPLETED })
                    .then(() => {
                        if (isMounted()) {
                            setTransaction({
                                ...transaction, status: ST_COMPLETED, status_: status[ST_COMPLETED]
                            })
                            setOpen(false)
                            toast.success(t('Transaction status has been updated'))
                        }
                    })
                    .catch(() => isMounted() && toast.error(t('Something went wrong')))
            }
        })

        setOpen(true)
    }

    const handleChargeback = () => {
        setOtherProps({
            title: t('Start chargeback for this transaction'),
            handleSubmit: (values) => {
                api.post(`/internal/transactions/${transactionId}/comments/`, { ...values, new_status: ST_CHARGEBACK })
                    .then(() => {
                        if (isMounted()) {
                            setTransaction({
                                ...transaction, status: ST_CHARGEBACK, status_: status[ST_CHARGEBACK]
                            })
                            setOpen(false)
                            toast.success(t('Transaction status has been updated'))
                        }
                    })
                    .catch(() => isMounted() && toast.error(t('Something went wrong')))
            }
        })

        setOpen(true)
    }

    const handleRefund = () => {
        setOtherProps({
            title: t('Start refund for this transaction'),
            handleSubmit: (values) => {
                api.post(`/internal/transactions/${transactionId}/comments/`, { ...values, new_status: ST_REFUND })
                    .then(() => {
                        if (isMounted()) {
                            setTransaction({
                                ...transaction, status: ST_REFUND, status_: status[ST_REFUND]
                            })
                            setOpen(false)
                            toast.success(t('Transaction status has been updated'))
                        }

                    })
                    .catch(() => isMounted() && toast.error(t('Something went wrong')))
            }
        })

        setOpen(true)
    }

    const handleHold = () => {
        setOtherProps({
            title: t('Put this transaction on hold'),
            handleSubmit: (values) => {
                api.post(`/internal/transactions/${transactionId}/comments/`, { ...values, new_status: ST_ONHOLD })
                    .then(() => {
                        if (isMounted()) {
                            setTransaction({
                                ...transaction, status: ST_ONHOLD, status_: status[ST_ONHOLD]
                            })
                            setOpen(false)
                            toast.success(t('Transaction status has been updated'))
                        }
                    })
                    .catch(() => isMounted() && toast.error(t('Something went wrong')))
            }
        })

        setOpen(true)
    }

    useEffect(() => {
        api.get(`/internal/transactions/${transactionId}/`)
            .then(({ data: { data } }) => isMounted() && setTransaction(data))
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [transactionId, t, isMounted, api])

    return (
        <Card>
            {loading ? <CardContent><FormSkeletonWrapper /></CardContent> :
                <>
                    <CardHeader
                        title={t('Transaction details')}
                        subheader={`${transaction.transaction_id}`}
                    />

                    <CardContent sx={{ textAlign: 'center' }}>
                        {(transaction.status !== ST_CHARGEBACK && transaction.status !== ST_REFUND && transaction.status !== ST_REJECTED) && (<ButtonGroup variant="outlined" aria-label="outlined primary button group">
                            {(
                                hasPermissions(TRANSACTIONS__COMPLETE) && (transaction.status === ST_CREATED || transaction.status === ST_ONHOLD || transaction.status === ST_FAILED)
                            ) ? (<Tooltip title={t('Complete transaction')}><Button onClick={handleComplete}><CheckIcon /></Button></Tooltip>) : ''}
                            {(
                                hasPermissions(TRANSACTIONS__CHARGEBACK) && (transaction.status === ST_COMPLETED || transaction.status === ST_ONHOLD)
                            ) ? (<Tooltip title={t('Chargeback')}><Button onClick={handleChargeback}><ArrowBackIcon /></Button></Tooltip>) : ''}
                            {(
                                hasPermissions(TRANSACTIONS__REFUND) && (transaction.status === ST_COMPLETED || transaction.status === ST_ONHOLD)
                            ) ? (<Tooltip title={t('Refund')}><Button onClick={handleRefund}><ReplayIcon /></Button></Tooltip>) : ''}
                            {(
                                hasPermissions(TRANSACTIONS__HOLD) && (transaction.status === ST_COMPLETED)
                            ) ? (<Tooltip title={t('Put on hold')}><Button onClick={handleHold}><PauseCircleOutlineIcon /></Button></Tooltip>) : ''}
                        </ButtonGroup>)}
                    </CardContent>

                    <CardContent>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2} justifyContent="flex-end">
                                    <ButtonWrapper
                                        variant="text"
                                        onClick={() => navigate('/transactions')}
                                        startIcon={<ArrowBackIcon />}>{t('Go back')}</ButtonWrapper>
                                </Stack>
                            </Grid>

                            <KeyValue
                                title={t('Transaction ID')}
                                value={transaction.transaction_id}
                                xs={12}
                                md={6}
                            />

                            <KeyValue
                                title={t('Status')}
                                value={t(transaction.status_ ? transaction.status_ : transaction.status)}
                                xs={6}
                                md={3}
                            />

                            <KeyValue
                                title={t('Date')}
                                value={transaction.status_date ? new Intl.DateTimeFormat('es-CL', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(transaction.status_date)) : ''}
                                xs={6}
                                md={3}
                            />

                            <KeyValue
                                title={t('Country')}
                                value={transaction.country}
                                xs={4}
                                md={2}
                            />

                            <KeyValue
                                title={t('Price')}
                                value={new Intl.NumberFormat('es-CL', { style: 'currency', currency: transaction.currency }).format(transaction.price)}
                                xs={4}
                                md={2}
                            />

                            <KeyValue
                                title={t('Currency')}
                                value={transaction.currency}
                                xs={4}
                                md={2}
                            />

                            <KeyValue
                                title={t('Method')}
                                value={transaction.payment_method.name}
                                xs={6}
                                md={3}
                            />

                            <KeyValue
                                title={t('Provider')}
                                value={transaction.payment_method?.method_provider?.name}
                                xs={6}
                                md={3}
                            />

                            <Box width="100%" />

                            <KeyValue
                                title={t('Customer')}
                                value={`${transaction.customer.first_name} ${transaction.customer.last_name}`}
                                xs={12}
                                md={6}
                            />

                            <KeyValue
                                title={t('Customer email')}
                                value={transaction.customer.email}
                                xs={12}
                                md={6}
                            />

                            <KeyValue
                                title={t('Product name')}
                                value={transaction.product_name}
                                xs={12}
                                md={5}
                            />

                            <KeyValue
                                title={t('Metadata')}
                                value={transaction.metadata}
                                xs={12}
                                md={7}
                            />

                            <Box width="100%" mb={2} />

                            <Grid item xs={12}>
                                <ReactJson
                                    displayDataTypes={false}
                                    src={transaction}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>

                    {otherProps ? <TransactionStatusModal
                        open={open}
                        handleClose={handleClose}
                        otherProps={otherProps}
                    /> : ''}
                </>
            }
        </Card>
    )
}
