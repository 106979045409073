import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import useIsMounted from "hooks/useIsMounted";
import useAxiosPrivate from "hooks/useAxiosPrivate";

export function createDowndloadFunction(url, setFetchingData, isMounted, axiosPrivate, t, params) {
    return () => {
        setFetchingData(true)

        const downloadToastId = toast.loading("Descargando")

        function finishToast(message, type) {
            toast.update(
                downloadToastId,
                {
                    render: message,
                    type: type,
                    isLoading: false,
                    autoClose: 5000,
                    closeOnClick: true,
                    closeButton: true,
                }
            )
        }

        axiosPrivate.get(url, { params: params, responseType: 'blob' })
            .then(response => {
                if (!isMounted()) {
                    return
                }
                console.log(response)
                let anchor = document.createElement('a')

                anchor.href = window.URL.createObjectURL(response.data)

                let headerLine = response.headers['content-disposition']
                let startFileNameIndex = headerLine.indexOf('"') + 1
                let endFileNameIndex = headerLine.lastIndexOf('"')
                let filename = headerLine.substring(startFileNameIndex, endFileNameIndex)

                anchor.download = filename

                anchor.click()

                anchor.remove()
                finishToast("Descarga completada, espere un momento para que la descarga apareza en su navegador", toast.TYPE.SUCCESS)

            })
            .catch((error) => {
                if (!isMounted()) {
                    return
                }
                if (error.response.status !== 500 && error?.response?.data?.text) {
                    error.response.data.text().then((data) => {
                        if (!isMounted()) {
                            return
                        }
                        const responseObjectFromDataJSONBlob = JSON.parse(data)
                        const errorData = responseObjectFromDataJSONBlob.error
                        if (!errorData?.message || !errorData?.errors?.detail) {
                            finishToast("Algo salió mal", toast.TYPE.ERROR)
                            return
                        }
                        const message = `${errorData.message}, ${errorData.errors.detail}`
                        finishToast(message, toast.TYPE.ERROR)
                    })
                }
                else {
                    finishToast("Algo salió mal", toast.TYPE.ERROR)
                }
            })
            .finally(() => isMounted() && setFetchingData(false))
    }
}


export function useDownloader() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    return (url, params = {}, setFetchingData = (() => { })) => createDowndloadFunction(url, setFetchingData, isMounted, api, t, params)()
}
