import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableBackdrop from 'components/TableUI/TableBackdrop'
import Paper from '@mui/material/Paper'

import DataRow from './DataRow'
import GenericFilters from './GenericFilters'

import useIsMounted from 'hooks/useIsMounted'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import { tableHeadBuild } from "./Table"
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/Pagination/Pagination'

import { getIntersection, objectFilterByKey } from 'utils/jsUtils'


export default function GenericTable({ metadata, url, extraCols = [], editableFields = null, hiddenFields, createdRows = [], hideWriteOnly = true }) {
    const api = useAxiosPrivate()
    const { t } = useTranslation()
    const [data, setData] = useState(null)
    const [paginationMeta, setPaginationMeta] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [filterParams, setFilterParams] = useState({})
    const isMounted = useIsMounted()

    const nonReadOnlyFields = () => new Set(Object.entries(metadata.field_info).filter((entry) => !entry[1].read_only).map((entry) => entry[0]))
    const editableFields_ = editableFields === null ? nonReadOnlyFields() : getIntersection(nonReadOnlyFields(), editableFields)

    const writeOnlyFields = () => new Set(Object.entries(metadata.field_info).filter((entry) => entry[1].write_only).map((entry) => entry[0]))
    const allHiddenFields = new Set([...hiddenFields, ...writeOnlyFields()])

    useEffect(() => {
        setData(null)
        api.get(url, { params: { page: pageNumber, ...filterParams } }).then((response) => {
            if (!isMounted()) return
            setData(response.data.data)
            setPaginationMeta(response.data?.meta)
        })
    }, [api, url, isMounted, pageNumber, filterParams])

    function editCallback(id, data) {
        return api.patch(`${url}${id}/`, data)
    }

    function showPagination() {
        return paginationMeta !== null && paginationMeta.total_pages > 1
    }

    function onPageChange(e, page) {
        setPageNumber(page)
    }

    function TablePagination() {
        if (!showPagination()) return null
        return <Pagination
            handleOnPageChange={onPageChange}
            page={paginationMeta.current_page}
            pages={paginationMeta.total_pages}
        />
    }

    return (
        <>
            <TableContainer component={Paper}>
                <GenericFilters metadata={{ ...metadata, field_info: objectFilterByKey((field) => !allHiddenFields.has(field), metadata.field_info) }} setGetParams={setFilterParams} />
                <TablePagination />
                <Table aria-label="simple table">
                    {metadata === null ?
                        <TableBackdrop open={true} />
                        : <>
                            {tableHeadBuild(
                                [...Object.values(objectFilterByKey((field) => !allHiddenFields.has(field), metadata.field_info)).map((field) => t(field.label)), ...extraCols.map((exCol) => exCol.title)]
                            )}
                            <TableBody>
                                {data ? [...createdRows, ...data].map((row, idx) => (
                                    <DataRow
                                        data={row}
                                        extraColFuncs={extraCols.map((exCol) => ((rowData) => exCol.func(rowData, metadata)))}
                                        key={idx}
                                        metadata={metadata}
                                        editableFields={editableFields_}
                                        hiddenFields={allHiddenFields}
                                        editCallback={editCallback}
                                    />
                                )) : <TableRow>
                                    {
                                        data === null ? <TableBackdrop open={data === null} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                    }
                                </TableRow>
                                }
                            </TableBody>
                        </>}
                </Table>
                <TablePagination />
            </TableContainer>
        </>
    )
}