import { Popover } from '@mui/material'
import { useState } from 'react'


export default function PopoverButton({ children, ButtonComponent, PopoverComponent = Popover, ...props }) {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return <>
        <ButtonComponent onClick={handleClick} />
        <PopoverComponent
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        >
            {children}
        </PopoverComponent>
    </>
}