import { useState, useEffect, useCallback } from 'react'
import { Card, CardContent, Grid, LinearProgress, Stack, Chip } from '@mui/material'
import { withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import WithdrawalsTable from './WithdrawalsTable'
import { toast } from 'react-toastify'
import useIsMounted from '../../../hooks/useIsMounted'
import AmountBox from '../../TableUI/AmountBox'
import PageTitle from '../../Misc/PageTitle'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import ToolsStack from "../../Misc/ToolsStack";
import LoadingButton from "@mui/lab/LoadingButton";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { WITHDRAWALS__VIEW } from "../../../enums/Caps";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useNavigate } from 'react-router-dom';

const FastLinearProgress = withStyles({
    bar: {
        // apply a new animation-duration to the `.bar` class
        transition: "0.1s"
    }
})(LinearProgress);

export default function WithdrawalsList() {
    const hasPermissions = useHasPermissions()

    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const navigate = useNavigate()

    const [total, setTotal] = useState({
        usd: 0,
        clp: 0,
        eur: 0
    })

    const [loading, setLoading] = useState(true)

    const [params, setParams] = useState({})

    const [data, setData] = useState([])

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const [selectedData, setSelectedData] = useState([])

    const [filteredData, setFilteredData] = useState([])

    const [selectedCurrencies, setSelectedCurrencies] = useState(new Set(['USD', 'EUR', 'CLP']))


    const [fetchTimeoutFraction_, setFetchTimeoutFraction_] = useState(null)
    const [fetchTimeoutFraction, setFetchTimeoutFraction] = useState(null)
    const [stepsInProgress, setStepsInProgress] = useState(0)

    const fetchTimeoutMs = 500
    const steps = 10


    // Regressive countdown
    useEffect(() => {
        if (fetchTimeoutFraction_ === steps) {
            setStepsInProgress(stepsInProgress + 1)
        }

        if (fetchTimeoutFraction_ !== steps && stepsInProgress > 1) {
            setStepsInProgress(stepsInProgress - 1)
            return
        }

        console.log(fetchTimeoutFraction_)
        setFetchTimeoutFraction(fetchTimeoutFraction_)
        if (fetchTimeoutFraction_ === null) {
            return
        }
        (new Promise(res => setTimeout(res, fetchTimeoutMs / steps))).then(() => {
            if (!isMounted()) {
                return
            }
            if (fetchTimeoutFraction_ < 1) {
                setFetchTimeoutFraction_(null)
                setStepsInProgress(stepsInProgress - 1)
                return
            }
            setFetchTimeoutFraction_(fetchTimeoutFraction_ - 1)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchTimeoutFraction_, isMounted])

    const startCountdown = () => {
        setFetchTimeoutFraction_(steps)
    }

    const handleOnPageChange = (e, page) => {
        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        setData([])

        api.get('/internal/withdrawals/to-process/')
            .then(({ data: { data, meta } }) => {
                if (isMounted()) {
                    const usd = data.filter(a => a.currency === 'USD').reduce((a, { withdrawable_amount }) => a + withdrawable_amount, 0)
                    const eur = data.filter(a => a.currency === 'EUR').reduce((a, { withdrawable_amount }) => a + withdrawable_amount, 0)
                    const clp = data.filter(a => a.currency === 'CLP').reduce((a, { withdrawable_amount }) => a + withdrawable_amount, 0)

                    setTotal({
                        ...total, clp: clp, usd: usd, eur: eur
                    })

                    setMeta(meta)
                    setData(data)
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, isMounted, api])

    useEffect(() => {
        const init = () => {
            setLoading(true)
            retrieveData()
        }

        init()
        // setFilteredData(data.filter(a => selectedCurrencies.has(a.currency)))
    }, [retrieveData, setLoading])

    useEffect(() => {
        if (fetchTimeoutFraction !== null) {
            return
        }
        const init = () => {
            setLoading(true)
            retrieveData()
        }

        init()
    }, [retrieveData, fetchTimeoutFraction, setLoading])

    useEffect(() => {
        setFilteredData(data.filter(a => selectedCurrencies.has(a.currency)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, setFilteredData])

    const handleOnCurrencyChange = (currency) => {
        startCountdown()
        if (selectedCurrencies.has(currency)) {
            setSelectedCurrencies(new Set([...selectedCurrencies].filter(a => a !== currency)))
            return
        }
        setSelectedCurrencies(new Set([...selectedCurrencies, currency]))
    }

    const isActive = (currency) => {
        return selectedCurrencies.has(currency)
    }

    const amountBoxArgs = (currency) => ({
        title: currency,
        onClick: () => handleOnCurrencyChange(currency),
        active: isActive(currency)
    })

    // Post require withdrawal parameter in data with a list of withdrawals ids
    const elementsToGroupPost = () => {
        api.post('/internal/withdrawals/grouped/', { withdrawals_id: selectedData })
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    console.log(data)
                    toast.success(t(`${data.withdrawals.length} withdrawals was processed`))
                    retrieveData()
                    setSelectedData([])
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    if (response.data.error.code === 400) {
                        toast.error(t('More than 1 currency was selected'))
                    }
                    else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Pending payouts')} />

                    <ToolsStack justifyContent="space-between">
                        {hasPermissions(WITHDRAWALS__VIEW) ? (<LoadingButton
                            variant="contained"
                            loading={loading}
                            onClick={elementsToGroupPost}
                            loadingPosition="start"
                            startIcon={<PlayCircleOutlineIcon />}
                            size="small"
                            disabled={!selectedData.length}
                        >
                            {t('Process')}
                        </LoadingButton>) : <span>.</span>}

                        <Stack direction="row" spacing={3}>
                            <Chip
                                label="In process"
                                variant="outlined"
                                color="success"
                                onClick={() => navigate('/in-process-payouts')}
                            />

                        </Stack>
                    </ToolsStack>
                    <Grid container spacing={2} sx={{ mt: 1, p: 2 }}>

                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1, p: 2 }}>
                        <Grid item xs={12} md={12} lg={9}>
                            <FastLinearProgress
                                value={fetchTimeoutFraction === null ? 100 : (fetchTimeoutFraction / steps) * 100}
                                variant={loading ? "indeterminate" : "determinate"}
                                style={{ opacity: fetchTimeoutFraction === null ? 0 : 0.5 }}
                            />
                        </Grid>
                        <Grid item container spacing={2} xs={12} md={12} lg={9}>
                            <Grid item xs={12} md={4} lg={4}>
                                <AmountBox amount={total.clp} loading={loading} fractionDigits={0} {...amountBoxArgs("CLP")} />
                            </Grid>

                            <Grid item xs={12} md={4} lg={4}>
                                <AmountBox amount={total.usd} loading={loading} {...amountBoxArgs("USD")} />
                            </Grid>

                            <Grid item xs={12} md={4} lg={4}>
                                <AmountBox amount={total.eur} title='EUR' loading={loading} {...amountBoxArgs("EUR")} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <WithdrawalsTable
                        data={filteredData}
                        setData={setData}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        retrieveData={retrieveData}
                    />
                </CardContent>
            </Card>
        </>
    )
}
