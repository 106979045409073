import { Card, CardContent } from '@mui/material'
import { Stack } from '@mui/material'
import { Grid } from '@mui/material'

import PageTitle from 'components/Misc/PageTitle'
import CreateComponent from './GenericCreateForm'
import GenericTable from './GenericTable'
import { translateChoicesInFieldInfo } from './metadataProcessing'

import useIsMounted from 'hooks/useIsMounted'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

/*
export class GenericPermissions {
    constructor(view, create, update, destroy) {
        this.view = view
        this.create = create
        this.update = update
        this.destroy = destroy
    }

    static hasPermissions = useHasPermissions()

    static permissionsMapping = {
        "view": this.view,
        "create": this.create,
        "update": this.update,
        "destroy": this.destroy,
    }

    check(permission) {
        const got_permission = this.permissionsMapping[permission]
        if (got_permission === null) return false
        if (typeof got_permission == "boolean") return got_permission
        return this.hasPermissions(got_permission)
    }
}
*/


export function ExtraCol(title, func) {
    return {
        "title": title,
        "func": (rowData, meta) => func(rowData, meta)
    }
}


export function GenericCrud({ url, extraCols = [], extraActions = [], editableFields = null, hiddenFields = new Set(), translatedChoiceFields, permissions, parentUrl }) {

    const api = useAxiosPrivate()
    const isMounted = useIsMounted()
    const [metadata, setMetadata] = useState(null)
    const [createdRows, setCreatedRows] = useState([])
    const { t } = useTranslation()

    const [translatedChoiceFieldsCalc] = useState(translatedChoiceFields ? translatedChoiceFields : new Set())

    useEffect(() => {
        api.options(url).then((response) => {
            const rawMetadata = response.data.data
            const fieldInfo = translateChoicesInFieldInfo(rawMetadata.field_info, translatedChoiceFieldsCalc, t)
            if (isMounted()) setMetadata({ ...rawMetadata, field_info: fieldInfo })
        })
    }, [api, url, isMounted, t, translatedChoiceFieldsCalc])

    return (
        <>
            {metadata === null ? null :
                <Grid container spacing={2}>
                    <Grid item xs={12} xl={12}>
                        <CreateComponent
                            metadata={metadata}
                            url={url}
                            newEntry={(data) => setCreatedRows([data, ...createdRows])}
                        />
                    </Grid>
                    {extraActions.map((Action, idx) =>
                        <Grid item xs={12} xl={12} key={idx}>
                            <Action metadata={metadata} url={url} />
                        </Grid>)}
                    <Grid item xs={12} xl={12}>
                        <GenericTable
                            metadata={metadata}
                            url={url}
                            extraCols={extraCols}
                            editableFields={editableFields}
                            hiddenFields={hiddenFields}
                            createdRows={createdRows}
                        />

                    </Grid>
                </Grid>}
        </>
    )
}


export function GenericList({ url, title, extraCols = [], editableFields = null, hiddenFields = new Set(), translatedChoiceFields = new Set(), permissions, parentUrl, ...otherParams }) {
    return <Card {...otherParams}>
        <CardContent>
            {title ?
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <PageTitle title={title} />
                </Stack>
                : null
            }

            <GenericCrud
                url={url}
                extraCols={extraCols}
                editableFields={editableFields}
                hiddenFields={hiddenFields}
                permissions={permissions}
                parentUrl={parentUrl}
                translatedChoiceFields={translatedChoiceFields}
            />
        </CardContent>
    </Card>
}