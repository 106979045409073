import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from 'react-i18next'
import TableBackdrop from '../../TableUI/TableBackdrop'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import CurrencyValue from '../../TableUI/CurrencyValue'
import DatetimeValue from '../../TableUI/DatetimeValue'
import { Box, Typography, Badge, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { toast } from 'react-toastify'
import useIsMounted from '../../../hooks/useIsMounted'
import { PM_BANK, PM_PAYPAL } from '../../../enums/Merchants'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useWithdrawalDeleteDialog } from './WithdrawalDelete';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../hooks/useHasPermissions'
import { WITHDRAWALS__COMPLETE, WITHDRAWALS__DELETE } from '../../../enums/Caps'


function withrawalCanBeSelected(withdrawal) {
    return withdrawal.status === 1 && withdrawal.merchant?.default_bank_account.reasons_to_not_include_in_bancoestado_bulk_payout.length === 0
}


export default function WithdrawalsTable({
    data,
    setData,
    loading = false,
    selectedData,
    setSelectedData,
    retrieveData
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [orderDirection, setOrderDirection] = useState("asc");

    const [openDeleteDialog, DeleteDialog] = useWithdrawalDeleteDialog(
        (withdrawalId) => {
            if (!isMounted()) {
                return
            }
            setData(data.filter(d => d.id !== withdrawalId))
        }
    )

    const handleRequestedAgain = (withdrawal_id) => {
        api.post('/internal/withdrawals/requested-again/', { withdrawal_id: withdrawal_id })
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    console.log(data)
                    toast.success(t(`${data.withdrawals.length} withdrawals was requested again`))
                    retrieveData()
                    setSelectedData([])
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    toast.error(t('Something went wrong'))
                }
            })
    }

    const handleManualComplete = async (withdrawal_id) => {
        try {
            await api.post(`/internal/withdrawals/${withdrawal_id}/complete/`)
            if (!isMounted()) {
                return
            }
            toast.success(t(`Withdrawal completed`))
            retrieveData()
            setSelectedData([])
        }
        catch (e) {
            if (!isMounted()) {
                return
            }
            toast.error(t('Something went wrong'))
        }
    }

    const handleChange = (e) => {
        const { checked, value } = e.target

        if (checked) {
            setSelectedData([
                ...selectedData, value * 1
            ])
        } else {
            setSelectedData(selectedData.filter(c => parseInt(c) !== parseInt(value)))
        }
    }

    const checkAll = (e) => {
        const { checked } = e.target

        if (checked) {
            setSelectedData(data.filter(r => withrawalCanBeSelected(r))
                .map(r => r.id))
        } else {
            setSelectedData([])
        }
    }

    const handleSortRequest = () => {
        setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
        data.reverse();
    };

    // md+width: calc(100vw - 300px)

    return (
        <>
            <Paper sx={{
                width: {
                    xs: 'calc(100vw - 100px)',
                    sm: 'calc(100vw - 330px)'
                }, overflow: 'hidden'
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox
                                                onChange={checkAll}
                                                indeterminate={selectedData.length > 0 && selectedData.length !== data.filter(r => withrawalCanBeSelected(r)).length}
                                                checked={selectedData.length > 0 && selectedData.length === data.filter(r => withrawalCanBeSelected(r)).length}
                                            />}
                                        />
                                    </FormGroup>
                                </TableCell>
                                <TableCell>{t('Invoice Nº')}</TableCell>
                                <TableCell>{t('Merchant')}</TableCell>
                                <TableCell align="center" onClick={handleSortRequest}>
                                    <TableSortLabel active={true} direction={orderDirection}>
                                        {t('Request date')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t('Bank info')}</TableCell>
                                <TableCell align="right">{t('Total')}</TableCell>
                                <TableCell align="right">{t('Withdrawable amount')}</TableCell>
                                <TableCell align="center">{t('Status')}</TableCell>
                                <TableCell align="center">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length ? data.map((row, idx) => (
                                <TableRow
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    value={row.id}
                                                    checked={selectedData.includes(row.id)}
                                                    onChange={handleChange}
                                                    disabled={!withrawalCanBeSelected(row)}
                                                />}
                                            />
                                        </FormGroup>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.invoice_code}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.merchant.username}
                                    </TableCell>
                                    <TableCell align="center">
                                        <DatetimeValue date={row.created_at} />
                                    </TableCell>
                                    <TableCell>
                                        {(() => {
                                            const non_agrupable_reasons = row.merchant.default_bank_account.reasons_to_not_include_in_bancoestado_bulk_payout
                                            return (row.merchant?.account_config?.payout_method === PM_BANK && row.merchant.default_bank_account) && (
                                                <Tooltip title={non_agrupable_reasons.map((reason, idx) => <Typography key={idx}>• {reason}</Typography>)}>
                                                    <Badge color="warning" badgeContent={non_agrupable_reasons.length}>
                                                        <Box>
                                                            <Typography>{`${row.merchant.default_bank_account?.bank_country}\n`}</Typography>
                                                            <Typography>{row.merchant.default_bank_account?.bank_name}</Typography>
                                                            <Typography>{row.merchant.default_bank_account?.account_owner}</Typography>
                                                            <Typography>{row.merchant.default_bank_account?.account_number}</Typography>
                                                        </Box>
                                                    </Badge>
                                                </Tooltip>
                                            )
                                        })()}

                                        {row.merchant?.account_config?.payout_method === PM_PAYPAL && (
                                            <>
                                                <Typography>Paypal</Typography>
                                                <Typography>{row.merchant?.account_config?.paypal_account}</Typography>
                                            </>
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue
                                            amount={row.total_payments}
                                            currency={row.currency}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue
                                            amount={row.withdrawable_amount}
                                            currency={row.currency}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography>{t(row.status_)}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {(hasPermissions(WITHDRAWALS__COMPLETE) && row.status === 5) ? (
                                            <IconButton
                                                color="primary"
                                                aria-label={t('Request again the payout')}
                                                onClick={() => handleRequestedAgain(row.id)}
                                                title={t('Request again the payout')}
                                                component="span">
                                                <ReplayCircleFilledIcon color="error" />
                                            </IconButton>) : ''}

                                        {(hasPermissions(WITHDRAWALS__COMPLETE) && row.status === 1) ? (
                                            <IconButton
                                                color="primary"
                                                aria-label={t('Confirm payout')}
                                                onClick={() => handleManualComplete(row.id)}
                                                title={t('Confirm payout')}
                                                component="span">
                                                <CheckCircleOutlineIcon />
                                            </IconButton>) : ''}

                                        {hasPermissions(WITHDRAWALS__DELETE) ? (<IconButton
                                            color="primary"
                                            aria-label={t('Delete payout')}
                                            onClick={() => { openDeleteDialog(row.id) }}
                                            title={t('Delete payout')}
                                            component="span">
                                            <HighlightOffIcon />
                                        </IconButton>) : ''}
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                }
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <DeleteDialog />
        </>
    )
}
