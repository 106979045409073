import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useAxiosPrivate from 'hooks/useAxiosPrivate'


export function useWithdrawalDeleteDialog(
    handleSuccess = () => null,
    handleFailure = () => null,
    handleFinalize = () => null
) {
    const api = useAxiosPrivate()

    const [open, setOpen] = useState(false)
    const [confirming, setConfirming] = useState(false)
    const [withdrawalId, setWithdrawalId] = useState()

    function openDialog(withdrawalId) {
        setOpen(true)
        setWithdrawalId(withdrawalId)
    }

    function handleClose() {
        setOpen(false)
        setConfirming(false)
    }

    async function handleConfirm() {
        try {
            setConfirming(true)
            const response = await api.delete(`/internal/withdrawals/${withdrawalId}/`)
            if (response.status >= 200 && response.status < 300) {
                toast.success('Payout deleted')
                handleSuccess(withdrawalId)
            }
        } catch (error) {
            const responseStatus = error?.response?.status
            if (!(responseStatus >= 200 && responseStatus < 300)) {
                return
            }
            toast.error('Error deleting payout')
            handleFailure()
        } finally {
            handleClose()
            handleFinalize()
        }
    }

    function WithdrawalDelete() {
        const { t } = useTranslation()

        const [confirmText, setConfirmText] = useState()

        // const [randomText, setRandomText] = useState('eliminar')
        const randomText = 'eliminar'

        const handleChange = (event) => {
            setConfirmText(event.target.value)
        }

        const handleRemove = () => {
            if (confirmText !== randomText) {
                toast.info(t('Please complete the required fields'))
                return
            }

            setConfirmText(null)
            handleConfirm()
        }

        return (
            <Dialog
                open={open}
                onClose={() => handleClose('delete')}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {t('Delete payout')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans i18nKey='deletionLegend'>
                            Upon confirming the deletion, the merchant's balance will be released and will be available to request a new withdrawal.
                        </Trans>
                    </DialogContentText>

                    <TextField
                        name='confirmText'
                        onChange={handleChange}
                        fullWidth
                        sx={{
                            mt: 2,
                            mb: 1
                        }}
                    />

                    <Typography variant='subtitle2'>
                        <Trans i18nKey="word" randomText={randomText}>To confirm the deletion, type <strong>{{ randomText }}</strong> in the text input field</Trans>
                    </Typography>

                </DialogContent>
                <DialogActions sx={{
                    p: 3
                }}>
                    <Button autoFocus onClick={() => handleClose('delete')}>{t('Cancel')}</Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleRemove}
                        disabled={confirming}
                    >{t('Delete payout')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return [
        openDialog,
        WithdrawalDelete,
    ]
}