export function getIntersection(setA, setB) {
    const intersection = new Set(
        [...setA].filter(element => setB.has(element))
    );

    return intersection;
}


export function objectFilterByKey(func, object) {
    return Object.fromEntries(Object.entries(object).filter(([key]) => func(key)))
}


export function objectFilter(func, object) {
    return Object.fromEntries(Object.entries(object).filter(([key, value]) => func({ key: key, value: value })))
}

export function objectMap(func, object) {
    return Object.fromEntries(Object.entries(object).map(([key, value]) => [key, func({ key: key, value: value })]))
}

export function numberLength(number) {
    return number.toString().length;
}

export function objectEqual(object1, object2) {
    return JSON.stringify(object1) === JSON.stringify(object2);
}