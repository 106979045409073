import { Typography } from "@mui/material"


function BankData(bankAccountData) {
    const hasNewBankData = bankAccountData.bank_id !== null
    return {
        bank_name: hasNewBankData ? bankAccountData.bank.name : bankAccountData.bank_name_old,
        bank_country: hasNewBankData ? bankAccountData.bank.country : bankAccountData.bank_country_old,
        using_new: hasNewBankData,
    }
}


/** Show text in red background if using legacy */
export function InListBankTextDisplay({ bankAccountData, name = false, country = false }) {
    if (name && country) {
        throw new Error("Cannot show both name and country")
    }
    const { bank_name, bank_country, using_new } = BankData(bankAccountData)
    const text = name ? bank_name : country ? bank_country : null
    return <Typography
        noWrap
        variant="body2"
        sx={{
            backgroundColor: using_new ? "transparent" : "error.main",
            color: using_new ? "text.primary" : "error.contrastText",
            borderRadius: 1,
            padding: 0.5,
        }}
    >
        {text}
    </Typography>
}