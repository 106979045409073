import { GenericCrud } from 'components/Common/GenericList'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import { useEffect } from 'react'
import ButtonWrapper from 'components/FormUI/ButtonWrapper/ButtonWrapper'
import { Card, CardContent, Grid, Stack } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PageTitle from 'components/Misc/PageTitle'


export default function DocumentFilesList() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { documentId, versionId } = useParams()

    const api = useAxiosPrivate()

    const [documentData, setDocumentData] = useState(null)

    useEffect(() => {
        api.get(`/internal/documents/${documentId}/`).then((response) => {
            setDocumentData(response.data.data)
        }
        )
    }, [documentId, api])

    return <Card>
        <CardContent>
            <Grid container spacing={2} mt={2} mb={2} style={{ width: "100%" }}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <PageTitle title={((documentData === null ? '' : `${documentData.name} / `)) + t("Files")} />
                        <ButtonWrapper
                            variant="text"
                            size="small"
                            onClick={() => navigate(`/documents/${documentId}/versions`)}
                            startIcon={<ArrowBackIcon />}>
                            {t('Go back')}
                        </ButtonWrapper>
                    </Stack>
                </Grid>
            </Grid>
            <GenericCrud
                url={`/internal/documents/${documentId}/versions/${versionId}/files/`}
                title={t("Files")}
                hiddenFields={new Set(["id", "version"])}
            />
        </CardContent>
    </Card>
}
