import GroupIcon from '@mui/icons-material/Group'
import PaymentsIcon from '@mui/icons-material/Payments'
import DashboardIcon from '@mui/icons-material/Dashboard'
import SettingsIcon from '@mui/icons-material/Settings'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ArticleIcon from '@mui/icons-material/Article'
import {
    ACCOUNT_CONFIG__UPDATE,
    ACCOUNT_CONFIG__VIEW,
    BANK_ACCOUNTS__CREATE,
    BANK_ACCOUNTS__DELETE,
    BANK_ACCOUNTS__UPDATE,
    BANK_ACCOUNTS__VIEW,
    CASHOUTS__DELETE,
    CASHOUTS__PROCESS,
    CASHOUTS__VIEW,
    COUNTRIES__CREATE,
    COUNTRIES__UPDATE,
    COUNTRIES__VIEW,
    COUNTRY_SPREADS__CREATE,
    COUNTRY_SPREADS__DELETE,
    COUNTRY_SPREADS__UPDATE,
    COUNTRY_SPREADS__VIEW,
    MERCHANTS__CREATE,
    MERCHANTS__UPDATE,
    MERCHANTS__VIEW,
    MERCHANTS__VIEW_BALANCE,
    MERCHANT_CONTACTS__CREATE,
    MERCHANT_CONTACTS__DELETE,
    MERCHANT_CONTACTS__UPDATE,
    MERCHANT_CONTACTS__VIEW,
    MERCHANT_PAYMENT_METHODS__UPDATE,
    MERCHANT_PAYMENT_METHODS__VIEW,
    METHOD_PROVIDERS__CREATE,
    METHOD_PROVIDERS__UPDATE,
    METHOD_PROVIDERS__VIEW,
    MISCELLANEOUS__CREATE,
    MISCELLANEOUS__DELETE,
    MISCELLANEOUS__UPDATE,
    MISCELLANEOUS__VIEW,
    PAYMENT_METHODS__CREATE,
    PAYMENT_METHODS__FEES,
    PAYMENT_METHODS__UPDATE,
    PAYMENT_METHODS__VIEW,
    TRANSACTIONS__CHARGEBACK,
    TRANSACTIONS__COMPLETE,
    TRANSACTIONS__HOLD,
    TRANSACTIONS__REFUND,
    TRANSACTIONS__VIEW,
    USERS__CREATE,
    USERS__UPDATE,
    USERS__VIEW,
    WITHDRAWALS__COMPLETE,
    WITHDRAWALS__DELETE,
    WITHDRAWALS__VIEW
} from './enums/Caps'


export const menu = [
    {
        icon: <DashboardIcon />,
        title: "Dashboard",
        items: [],
        to: '/'
    },
    {
        icon: <PaymentsIcon />,
        title: "Transactions",
        to: '/transactions',
        caps: [
            TRANSACTIONS__VIEW,
            TRANSACTIONS__CHARGEBACK,
            TRANSACTIONS__COMPLETE,
            TRANSACTIONS__HOLD,
            TRANSACTIONS__REFUND
        ]
    },
    {
        icon: <GroupIcon />,
        title: "Merchants",
        to: '/merchants',
        caps: [
            MERCHANTS__VIEW,
            MERCHANTS__CREATE,
            MERCHANTS__UPDATE,
            MERCHANTS__VIEW_BALANCE,
            MERCHANT_CONTACTS__VIEW,
            MERCHANT_CONTACTS__CREATE,
            MERCHANT_CONTACTS__UPDATE,
            MERCHANT_CONTACTS__DELETE,
            MERCHANT_PAYMENT_METHODS__VIEW,
            MERCHANT_PAYMENT_METHODS__UPDATE,
            ACCOUNT_CONFIG__VIEW,
            ACCOUNT_CONFIG__UPDATE,
            BANK_ACCOUNTS__VIEW,
            BANK_ACCOUNTS__CREATE,
            BANK_ACCOUNTS__UPDATE,
            BANK_ACCOUNTS__DELETE,
            COUNTRY_SPREADS__VIEW,
            COUNTRY_SPREADS__CREATE,
            COUNTRY_SPREADS__UPDATE,
            COUNTRY_SPREADS__DELETE,
            MISCELLANEOUS__VIEW,
            MISCELLANEOUS__CREATE,
            MISCELLANEOUS__UPDATE,
            MISCELLANEOUS__DELETE
        ]
    },
    {
        icon: <AccountBalanceIcon />,
        title: "Payouts",
        items: [
            {
                title: "Pending payouts",
                to: '/pending-payouts'
            },
            {
                title: "In process payouts",
                to: '/in-process-payouts'
            },
            {
                title: "Payment list",
                to: '/payment-list'
            },
            {
                title: "To pay payouts",
                to: '/to-pay-payouts'
            }
        ],
        caps: [
            WITHDRAWALS__VIEW,
            WITHDRAWALS__COMPLETE,
            WITHDRAWALS__DELETE
        ]
    },
    {
        icon: <AddToHomeScreenIcon />,
        title: "Payment methods",
        items: [
            {
                title: "Payment methods",
                to: '/payment-methods',
                caps: [
                    PAYMENT_METHODS__VIEW,
                    PAYMENT_METHODS__CREATE,
                    PAYMENT_METHODS__UPDATE,
                    PAYMENT_METHODS__FEES,
                ]
            },
            {
                title: "Providers",
                to: '/method-providers',
                caps: [
                    METHOD_PROVIDERS__VIEW,
                    METHOD_PROVIDERS__CREATE,
                    METHOD_PROVIDERS__UPDATE
                ]
            },
            {
                title: "Methods requested",
                to: '/methods-requested',
                caps: [
                    MERCHANT_PAYMENT_METHODS__UPDATE
                ]
            },
        ],
        caps: [
            PAYMENT_METHODS__VIEW,
            PAYMENT_METHODS__CREATE,
            PAYMENT_METHODS__UPDATE,
            PAYMENT_METHODS__FEES,
            METHOD_PROVIDERS__VIEW,
            METHOD_PROVIDERS__CREATE,
            METHOD_PROVIDERS__UPDATE
        ]
    },
    {
        icon: <EqualizerIcon />,
        title: "Charts",
        items: [
            {
                title: "Sales stats",
                to: '/charts/sales'
            },
            {
                title: "Traffic stats",
                to: '/charts/traffic'
            }
        ],
        caps: [
            TRANSACTIONS__VIEW
        ]
    },
    {
        icon: <AttachMoneyIcon />,
        title: "Cashouts",
        items: [
            {
                title: "Cashout list",
                to: '/cashouts'
            },
            {
                title: "Pending Cashouts",
                to: '/cashouts/pending'
            },
            {
                title: "Active Cashouts",
                to: '/cashouts/active'
            }
        ],
        caps: [
            CASHOUTS__VIEW,
            CASHOUTS__PROCESS,
            CASHOUTS__DELETE
        ]
    },
    {
        icon: <GroupIcon />,
        title: "Users",
        to: '/users',
        caps: [
            USERS__VIEW,
            USERS__CREATE,
            USERS__UPDATE
        ]
    },
    {
        icon: <ArticleIcon />,
        title: "Documents",
        to: '/documents',
        // TODO: add caps
    },
    {
        icon: <SettingsIcon />,
        title: "Settings",
        items: [
            {
                title: "Countries",
                to: '/settings/countries'
            },
            {
                title: "Spreads",
                to: "/spreads",
                caps: [
                    COUNTRY_SPREADS__VIEW,
                    COUNTRY_SPREADS__UPDATE,
                ]
            },
        ],
        caps: [
            COUNTRIES__VIEW,
            COUNTRIES__CREATE,
            COUNTRIES__UPDATE
        ]
    },
]