import { useEffect, useState, useCallback } from 'react'
import { Card, CardContent, Grid, Hidden, Stack } from '@mui/material'
import FormSkeletonWrapper from '../../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import BankAccountsForm from './BankAccountsForm'
import { toast } from 'react-toastify';
import { parseErrors, replaceNullValues } from '../../../../services/utils'
import PageTitle from '../../../Misc/PageTitle';
import MerchantMenu from '../MerchantMenu'
import MerchantAlias from '../MerchantAlias'
import MerchantSubmenu from '../MerchantSubmenu'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { BANK_ACCOUNTS__UPDATE } from '../../../../enums/Caps'
import useHasPermissions from '../../../../hooks/useHasPermissions'


export default function BankAccountEdit() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [data, setData] = useState()

    const { userId, accountId } = useParams()

    const [isLoading, setLoading] = useState(true)

    const getAccount = useCallback(() => {
        api.get(`/internal/merchants/${userId}/bank-accounts/${accountId}/`)
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    setData(replaceNullValues({
                        ...data,
                        bank_country: data?.bank?.country || '',
                        bank_id: data?.bank?.id || '',
                    }))
                }
            })
            .catch(() => isMounted() && toast.error(t('something went wrong!')))
            .finally(() => isMounted() && setLoading(false))
    }, [accountId, userId, t, api, isMounted])

    const handleSubmit = (values, { setFieldError }) => {
        api.put(`/internal/merchants/${userId}/bank-accounts/${accountId}/`, values)
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Bank account has been updated'))
                    navigate(`/merchants/${userId}/bank-accounts`)
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const handleCancel = () => {
        navigate(`/merchants/${userId}/bank-accounts`)
    }

    const FORM_VALIDATION = Yup.object().shape({
        notification_email: Yup.string()
            .email(t('You must enter a valid email address')),
        bank_id: Yup.string()
            .required(t('This field is required')),
        bank_country: Yup.string()
            .required(t('This field is required')),
        bank_address: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 1024 })),
        account_owner: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            .required(t('This field is required')),
        account_number: Yup.string()
            .required(t('This field is required')),
        account_owner_personal_id: Yup.string()
            .max(64, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 64 }))
            .when('bank_country', {
                is: (country) => country === 'CL',
                then: Yup.string()
                    .test('chilean-rut', t('Document number must be a valid chilean RUT'), (rut) => {
                        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rut)) {
                            return false;
                        }

                        const parts = rut.split('-')
                        const number = parts[0]
                        const dv = parts[1] === 'K' ? 'k' : parts[1]

                        const check = (T) => {
                            let M = 0,
                                S = 1;
                            for (; T; T = Math.floor(T / 10))
                                S = (S + T % 10 * (9 - M++ % 6)) % 11;
                            return String(S ? S - 1 : 'k');
                        }

                        return check(number) === dv
                    })
            })
            .required(t('This field is required')),
        status: Yup.number()
            .required(t('This field is required')),
    })

    useEffect(() => {
        const init = () => {
            getAccount()
        }

        init()
    }, [getAccount])

    return (
        <>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <PageTitle title={t('Edit bank account')} />

                        <Hidden xlUp={true}>
                            {userId ? <MerchantMenu id={userId} /> : ''}
                        </Hidden>
                    </Stack>

                    <MerchantAlias />

                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={2}>
                            <MerchantSubmenu current='bank-accounts' id={userId} />
                        </Grid>
                        <Grid item xs={12} xl={10}>
                            {
                                isLoading ? <FormSkeletonWrapper /> :
                                    <BankAccountsForm
                                        isNew={false}
                                        handleSubmit={handleSubmit}
                                        handleCancel={handleCancel}
                                        initialFormState={data}
                                        FORM_VALIDATION={FORM_VALIDATION}
                                        merchant_id={userId}
                                        hasPermissions={hasPermissions(BANK_ACCOUNTS__UPDATE)}
                                    />
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
