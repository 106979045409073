import { Typography } from '@mui/material'

export default function DatetimeValue({
    date,
    text = '',
    hour = true
}) {
    if (hour) {
        return (
            <Typography noWrap>
                {text} {date && Intl.DateTimeFormat('es-CL', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(date))}
            </Typography>
        )
    } else {
        return <Typography noWrap>
            {text} {date && Intl.DateTimeFormat('es-CL', { dateStyle: 'short',timeZone: 'UTC' }).format(new Date(date))}
        </Typography>
    }
}
