import { GenericList } from 'components/Common/GenericList'
import ButtonWrapper from 'components/FormUI/ButtonWrapper/ButtonWrapper'
import { ExtraCol } from 'components/Common/GenericList'

import GradingIcon from '@mui/icons-material/Grading'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'


function VersionsButton(entry) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return <ButtonWrapper
        variant="text"
        size="small"
        onClick={() => navigate(`${entry.id}/versions`)}
        startIcon={<GradingIcon />}>
        {t('Versions')}
    </ButtonWrapper>
}

export default function DocumentsList() {
    const { t } = useTranslation()

    return <GenericList
        url={"/internal/documents/"}
        title={t("Documents")}
        hiddenFields={new Set(["id"])}
        extraCols={[
            new ExtraCol("", VersionsButton)
        ]}

    />
}
