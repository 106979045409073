import { useState, useEffect } from 'react'
import { Alert, Button, Card, CardContent, Fab, IconButton, Stack, Typography } from '@mui/material'
import FileUploadTable from './FileUploadTable'
import PageTitle from '../../../Misc/PageTitle'
import ToolsStack from '../../../Misc/ToolsStack'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import useIsMounted from '../../../../hooks/useIsMounted'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import { useNavigate, useParams  } from 'react-router-dom'
import { toast } from 'react-toastify'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { parseErrors } from 'services/utils'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'

const Input = styled('input')({
    display: 'none',
});

export default function FileUpload() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const navigate = useNavigate()

    const { groupId } = useParams()

    const [loading, setLoading] = useState(false)

    const [file, setFile] = useState()

    const [data, setData] = useState([])

    const [isSent, setIsSent] = useState(false)

    const api = useAxiosPrivate()

    useEffect(() => {
        const sendFile = (base64Data) => {
            const dataTemporal = {
                'file': base64Data,
                'group_id': groupId
            }
            api.post("/internal/withdrawals/upload/", dataTemporal)
                .then(({ data: { data } }) => {
                    if (isMounted()) {
                        setData(data)
                        setIsSent(true)
                        setLoading(false)
                    }
                })
                .catch(({ response }) => {
                    if (isMounted()) {
                        const errors = parseErrors(response);
                        if (typeof errors === "string") {
                            toast.error(t(errors));
                        } else {
                            console.log(response)
                            toast.error(t("Something went wrong"));
                        }
                    }
                })
        }
        if (!file || file.length === 0) {
            return
        }
        const reader = new FileReader()
        reader.onload = (event) => {
            const base64Data = reader.result.split(',')[1]
            sendFile(base64Data)
        }
        reader.readAsDataURL(file)
    }, [file, api, isMounted, t, groupId])

    const handleChange = (event) => {
        setLoading(true);
        setFile(event.target.files[0])
    }

    const handleResetFile = () => {
        setFile(null)
        setData([])
        setIsSent(false)
    }

    const processWithdrawals = () => {
        const dataTemporal = { 'rows': data.filter(row => row.id !== undefined) }
        api.post("/internal/withdrawals/process/", dataTemporal)
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    navigate('/payment-list')
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response);
                    if (typeof errors === "string") {
                        toast.error(t(errors));
                    } else {
                        console.log(response)
                        toast.error(t("Something went wrong"));
                    }
                }
            })
    }
    return (
        <Card>
            <CardContent>
                {(!!file && data.length > 0) && (<Fab
                    variant="extended"
                    color="success"
                    onClick={processWithdrawals}
                    sx={{
                        position: 'fixed',
                        bottom: 26,
                        right: 26
                    }}>
                    <FactCheckIcon sx={{ mr: 1 }} />
                    {t('Process')}
                </Fab>)}

                <PageTitle title={t('Upload Banco Estado response')} />
                <ToolsStack justifyContent="space-between">
                    <Stack direction="row" spacing={3}>
                        {!!file && (<LoadingButton
                            variant="contained"
                            loading={loading}
                            onClick={processWithdrawals}
                            loadingPosition="start"
                            startIcon={<UploadFileIcon />}
                            size="small"
                            disabled={loading}
                        >
                            {t('Process')}
                        </LoadingButton>)}

                        <label htmlFor="contained-button-file">
                            <Input
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={handleChange}
                                type="file"
                                id="contained-button-file"
                            />
                            {!file && (<Button variant="contained" component="span">
                                Seleccionar archivo
                            </Button>)}
                        </label>
                    </Stack>

                    <Button
                        variant="text"
                        onClick={() => navigate('/in-process-payouts')}
                        startIcon={<ArrowBackIcon />}>{t('Go back')}</Button>
                </ToolsStack>

                {!!file && (
                    <div>
                        <Typography sx={{
                            color: 'blue',
                            my: '1rem'
                        }}>
                            {file.name} ({Math.round(file.size / 1000)}kb)
                            <IconButton
                                variant="contained"
                                color="error"
                                onClick={handleResetFile}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        </Typography>
                    </div>
                )}

                {(!data.length && isSent) && (<Alert icon={false} severity="info" variant="outlined">
                    No se encontraron transacciones en el archivo subido
                </Alert>)}

                {(data.length > 0) && (<FileUploadTable data={data} loading={loading} />)}
            </CardContent>
        </Card>
    )
}
