const ST_INCOMPLETE = 0
const ST_ACTIVE = 1
const ST_SUSPENDED = 2

const PM_PAYPAL = 1
const PM_BANK = 2

const CONTACT_COMMERCIAL = 'Comercial'
const CONTACT_OPERATIONAL = 'Operaciones'
const CONTACT_CUSTOMER_SERVICE = 'Servicio al Cliente'

const languages = {
    "ES": "Spanish",
    "EN": "English",
    "PT": "Portuguese",
}

const currencies = {
    'CLP': 'CLP',
    'USD': 'USD',
    'EUR': 'EUR',
}

const payouts_methods = {
    1: 'Paypal',
    2: 'Bank transfer'
}

const method_status = {
    0: 'Disabled',
    1: 'Requested',
    2: 'Enabled',
    3: 'Locked',
    4: 'Default (Unrequested)',
}

/**
 * Estados actuales de merchants
 */
const status = {
    /**
     * Puede ingresar al BO, no puede realizar pagos
     * + Backoffice
     * x Pagos
     * x Retiros
     */
    0: 'Incomplete',
    /**
     * Puede ingresar BO, puede funcionar normalmente
     * + Backoffice
     * + Pagos
     * + Retiros
     */
    1: 'Active',
    /**
     * Cuenta bloqueada, No puede operar en Paygol
     * x Backoffice
     * x Pagos
     * x Retiros
     */
    2: 'Suspended',
    /**
     * Puede procesar pagos, no puede retirar dinero
     * + Backoffice
     * + Pagos
     * x Retiros
     */
    3: 'Limited',
    /**
     * Puede retirar su dinero, no puede procesar pagos (Para cuentas en proceso de cierre)
     * + Backoffice
     * x Pagos
     * + Retiros
     */
    4: 'Terminated',
    5: 'Conditional'
    /**
     * No puede retirar ni procesar pagos
     * + Backoffice
     * x Pagos
     * X Retiros
     */
}

const status_info = {
  0: "Can: Enter the Backoffice",
  1: "Can: Enter the Backoffice, Process payments, Request withdrawals",
  2: "The merchant cannot use Paygol",
  3: "Can: Enter the Backoffice, Process payments",
  4: "Can: Enter the Backoffice, Request withdrawals",
  5: "Can: Enter the Backoffice",
};

const bank_accounts = {
    status: {
        2: 'Disabled',
        1: 'Enabled'
    }
}

const ipn_versions = {
    "1": "IPN v1",
    "2": "IPN v2",
}

const address_types = {
    1: "Company Address",
    2: "Representative Address"
}

export {
    currencies,
    languages,
    method_status,
    status,
    status_info,
    ipn_versions,
    bank_accounts,
    ST_ACTIVE,
    ST_INCOMPLETE,
    ST_SUSPENDED,
    payouts_methods,
    PM_PAYPAL,
    PM_BANK,
    CONTACT_COMMERCIAL,
    CONTACT_OPERATIONAL,
    CONTACT_CUSTOMER_SERVICE,
    address_types
}